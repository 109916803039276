.timeWrapper {
  display: flex;
  height: 271px;

  > div {
    width: 48px;
  }

  :global {
    .os-viewport-native-scrollbars-invisible#os-dummy-scrollbar-size,
    .os-viewport-native-scrollbars-invisible.os-viewport {
      border-right: 1px solid var(--color-delimiter-main);
    }
  }
}

.cell {
  display: flex;
  min-width: 48px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-primary-hover);
  }

  &.selected {
    background-color: var(--color-primary-selected);
  }
}

.lastFlex {
  height: calc(100% - 26px);
}
