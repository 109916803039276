.list {
  display: flex;
  width: 100%;
  min-height: 40px;
  transition: transform 0.5s ease-in-out;
  touch-action: pan-y;

  & .slide {
    position: relative;
    width: 100%;
    flex: 1 0 100%;
    z-index: 100;
  }
}

.nodeWrapper {
  display: flex;
  height: 100%;
  align-items: center;
}
