/* stylelint-disable color-no-hex */
.column {
  position: relative;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-direction: column;
}

.showGrid:before {
  content: '';
  position: absolute;
  border: 1px dashed #f2994a55;
  z-index: -1;
  inset: -1px 0 0 -1px;
}

.column.controllable:before {
  box-shadow: inset 0 0 0 1px #18a0fb;
  z-index: 1;
}

.column.controllable:hover:before {
  box-shadow: inset 0 0 0 2px #18a0fb;
  z-index: 1;
  inset: -1px;
}

@media print {
  .column {
    display: block !important;
  }
}

.fillMode {
  pointer-events: none;
}
