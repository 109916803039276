.root {
  display: flex;
  max-height: 250px;
  flex-direction: column;
  padding: 10px 12px;
  overflow: auto;
}

.item {
  display: flex;
  cursor: pointer;
}

.time {
  width: 50px;
  flex-shrink: 0;
  margin-right: 4px;
  line-height: 18px;
  color: var(--color-text-main);
}

.title {
  width: 230px;
  line-height: 18px;
  color: var(--color-text-secondary);
}
