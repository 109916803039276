.confirm__modal-title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.24;
  line-height: 24px;
  color: var(--color-text-main);
}

.confirm__modal-info {
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-secondary);
}

.confirm__modal-footer {
  display: flex;
  justify-content: flex-end;
}

.confirm__modal-footer .ant-btn-primary {
  margin-right: 12px;
}

.confirm__modal-warning {
  font-size: 32px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: var(--color-warning);
}
