.root {
  position: absolute;
}

.deleteSpot {
  position: absolute;
  display: none;
  width: 12px;
  height: 12px;
  top: -10px;
  right: -5px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: var(--box-shadow-small);
  background-color: var(--color-background-main);
  font-size: 9px;
  z-index: 10;
  color: var(--color-text-main);
}

.rings {
  position: relative;
  width: 100%;
  height: 100%;
  top: 100%;
  z-index: 1;

  .ringlet {
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 50%;
    background: var(--spot-color);
    transform: translate(-50%, -50%) scale(0.3, 0.3);
    animation: ring-1 3000ms ease-out infinite;
    opacity: 0;
    z-index: 1;

    &:nth-of-type(2) {
      animation-delay: 600ms;
    }

    &:nth-of-type(3) {
      animation-delay: 1200ms;
    }
  }
}

.ringWrapper.enableDelete {
  &:hover {
    .deleteSpot {
      display: flex;
    }
  }
}

.visible {
  display: flex;
}

@keyframes ring-1 {
  0% {
    transform: translate(-50%, -50%) scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    transform: translate(-50%, -50%) scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.3, 1.3);
    opacity: 0;
  }
}
