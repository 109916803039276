.selectedIndexBlock {
  display: flex;
  gap: 4px;
}

.closeIcon {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 4px 5px;
  visibility: hidden;
}

.valueText {
  padding: 0 5px;
  font-size: 11px;
}

.numberWrapper {
  position: relative;
  display: flex;
  height: 18px;
  min-width: 18px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: var(--color-background-dark);
  z-index: 10;
  color: var(--color-background-main);

  &:hover {
    cursor: pointer;
  }

  &:hover .valueText {
    opacity: 0;
  }

  &:hover .closeIcon {
    visibility: visible;
  }
}
