.root {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-flow: column;
  align-items: center;
  gap: 0.5rem;
  color: var(--textPercents-color);
}

.circleProgress {
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
}

.stroke,
.trail {
  fill: transparent;
}

.trail {
  stroke: var(--color-delimiter-main);
  stroke-width: 2px;
}

.stroke {
  stroke: var(--color-primary-default);
  stroke-width: 2px;
  stroke-linecap: inherit;
}
