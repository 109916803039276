.root {
  position: relative;
  height: 100%;
  min-height: 40px;
  overflow: hidden;
}

.wrap {
  position: absolute;
  display: flex;
  height: 100%;
  overflow: hidden;
  inset: 0;
}

.splitterBody {
  position: relative;
  height: 100%;
  z-index: 1;
}

.left,
.right {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.cropperLeft,
.cropperRight {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  //background: var(--color-background-main);
  overflow: hidden;
}

.text,
.blockHidden {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 40px;
  max-width: 100%;
  flex-wrap: wrap;
  word-break: break-word;
}

.blockHidden {
  opacity: 0;
  z-index: -1;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  z-index: 0;
}

.customIcon {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.pdf {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .img {
    width: 50%;
    margin-bottom: 5px;
  }
}

@media print {
  .pdf {
    display: block;

    .item {
      display: inline-block;
      break-inside: avoid;
    }
  }
}
