.root {
  display: flex;
  cursor: pointer;
}

.icon {
  margin-right: 16px;
}

.content {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.title {
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
