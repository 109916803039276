.root {
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0 32px;
  border-left: 1px solid var(--color-delimiter-main);
}

.icon {
  margin-right: 8px;
  font-size: 18px;
}

.fireTime {
  color: var(--color-warning);
}
