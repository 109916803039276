.root {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;

  .layer {
    visibility: hidden;

    .label {
      position: relative;
      margin-bottom: 0;
      vertical-align: baseline;
    }
  }
}
