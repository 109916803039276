.list {
  margin: 0;
  list-style: none;
}

.list__items {
  position: relative;
}

.list-item {
  margin-bottom: 16px;
  background: transparent;
}

.text {
  display: inline;
  font-size: 16px;
  vertical-align: middle;
}
