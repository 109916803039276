.root {
  display: flex;
  gap: 18px;
  margin-bottom: 20px;
}

.item {
  display: flex;
  width: 60px;
  height: 90px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: var(--border-radius-main);
  cursor: pointer;

  &:hover {
    background-color: var(--color-primary-hover);
  }
}

.preview {
  display: flex;
  width: 52px;
  height: 52px;
  align-items: center;
  justify-content: center;
  margin: 4px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: var(--border-radius-main);
  background-color: var(--color-background-main);
  color: var(--color-text-secondary);
}

.selected {
  background-color: var(--color-primary-selected);

  &:hover {
    background-color: var(--color-primary-selected);
  }
}
