.resizer {
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  cursor: col-resize;
  z-index: 1;

  &:hover,
  &.resizing {
    background-color: var(--color-input-focus);
  }
}
