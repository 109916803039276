.root {
  position: relative;
  display: inline-block;
}

.pdf {
  margin: 5px 0;
}

.pdfItem {
  width: 280px;
  height: 424px;
  max-height: 424px;
}

.pdfItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
