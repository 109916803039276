.root {
  border: none;
  color: var(--color-text-main);

  &:focus-visible {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      border-radius: 6px;
      outline: 2px solid transparent;
      outline-color: var(--color-input-focus);
      outline-offset: 2px;
      inset: -2px -4px;
    }
  }
}

.free {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.base {
  font-weight: 500;
  text-decoration: underline;
  -webkit-text-decoration-color: rgb(28, 28, 28, 0.3);
  text-decoration-color: rgb(28, 28, 28, 0.3);
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;

  &:hover {
    color: var(--color-text-main);
  }
}

.fluid {
  display: block;
  width: 100%;
  height: 100%;
}

.disabled {
  cursor: not-allowed;
  color: var(--color-placeholder);

  &:hover {
    color: var(--color-placeholder);
  }
}
