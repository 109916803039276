.root {
  position: relative;
  display: inline-block;
}

.disabled {
  cursor: auto;
  opacity: 0.5;
}

.tooltip {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.slider {
  flex-grow: 1;
}

.close {
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 0;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 20px 0;
}

.arrow {
  cursor: pointer;
}
