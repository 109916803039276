.root {
  display: flex;
  height: 100%;
  flex-direction: column;

  &.left {
    flex-direction: row;
  }

  &.right {
    flex-direction: row-reverse;
  }
}

.controls {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-delimiter-main);
  overflow: hidden;

  &.left,
  &.right {
    align-items: flex-start;
    border-right: 1px solid var(--color-delimiter-main);
    border-bottom: none;
  }

  &.right {
    border-right: none;
    border-left: 1px solid var(--color-delimiter-main);
  }
}

.tabBarExtraContentGrow {
  flex-grow: 1;
}

.content {
  flex-grow: 1;
  overflow: hidden;
}

.grow {
  flex-grow: 1;
}

.arrowLeft,
.arrowRight {
  position: absolute;
  display: flex;
  top: 0;
  font-size: 46px;
  cursor: pointer;
  z-index: 1;
}

.arrowLeft {
  left: 0;
}

.arrowRight {
  right: 0;
  transform: rotate(180deg);
}

.tabListWrap {
  position: relative;
  display: flex;
  // width: 100%;
  // flex-grow: 1;
  flex-direction: column;
  // border-bottom: 1px solid var(--color-delimiter-main);
  overflow: auto;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .tabList {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    margin-left: 0;

    &.left,
    &.right {
      flex-direction: column;
    }
  }

  &.isDrawArrow {
    .tabList {
      :first-child {
        padding-left: 46px;
      }

      :last-child {
        padding-right: 46px;
      }
    }
  }
}

.tabsGrow.tabListWrap {
  flex-grow: 1;
}

.contentPosition {
  justify-content: flex-end;
}

//.top {
//  flex-grow: 0;
//}

.activeTab {
  position: absolute;
  height: 2px;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--color-primary-default);
  transition: transform 0.2s;

  &.left {
    border-top-left-radius: 0;
    border-bottom-right-radius: 10px;
  }

  &.right {
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
  }
}
