.inputWrapper {
  display: flex;
  width: 100%;
  gap: 4px;
}

.item {
  display: flex;
  width: 64px;
  flex-direction: column;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--color-text-secondary);
}
