.root {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
  color: var(--color-text-main);

  &.active {
    color: var(--color-primary-default);
  }

  &:hover {
    color: var(--color-primary-default);
  }
}
