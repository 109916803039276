.root {
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-color: transparent;
  border-radius: 50%;
}

.line {
  position: absolute;
  top: 0;
  bottom: -14px;
  left: 11px;
  border-left: 1px dashed var(--color-placeholder);
  z-index: 0;
}

.lockIcon {
  display: flex;
  height: 24px;
  min-width: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-white);
  z-index: 1;
}

.status {
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: 1px solid var(--color-background-secondary);
  border-radius: 50%;
  background: var(--color-background-main);
  z-index: 1;
}

.progress {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
}

.statusDone {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 24px;
  color: var(--color-primary-default);
}
