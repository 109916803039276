.root {
  position: relative;
}

.iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

.overlay {
  position: absolute;
  z-index: 1;
  inset: 0;
}

.help {
  font-size: 14px;
}

.helpList {
  padding-left: 24px;
}
