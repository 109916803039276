.root {
  display: flex;
  min-height: 220px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :global {
    .ant-progress-inner {
      border-radius: var(--progressResult-borderRadius);
    }

    .ant-progress-bg {
      border-radius: var(--progressResult-borderRadius);
    }
  }

  .count {
    margin-bottom: 16px;
    font-weight: 700;
  }

  .tryAgain {
    display: flex;
    margin-top: 36px;
    margin-bottom: 36px;

    :global(button):hover {
      color: unset;
    }
  }

  .progress {
    width: 140px;
  }

  .buttonTry {
    display: flex;
    border: none;
    background: transparent;
  }

  .textTry {
    margin-left: 8px;
    font-size: 14px;
  }
}
