.root {
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  color: var(--color-text-main);
}

.active {
  color: var(--color-primary-default);
}

.disabled {
  cursor: not-allowed;
  color: var(--color-placeholder);
}
