.root {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px 6px;
  border-radius: var(--border-radius-main);
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;

  &:not(.disabled).hovered {
    background-color: var(--color-primary-hover);
  }

  &.selected {
    background-color: var(--color-primary-selected);
  }

  &.disabled {
    cursor: not-allowed;
    color: var(--color-placeholder);
  }

  &.xmini {
    height: 20px;
  }

  &.mini {
    height: 28px;
  }

  &.medium {
    height: 32px;
  }

  &.large {
    height: 40px;
  }
}

.horizontal {
  position: relative;
  padding: 0 12px;
  font-weight: 500;

  &:not(:first-child):before {
    content: '';
    position: absolute;
    height: 28px;
    left: -4px;
    border-left: 1px solid var(--color-delimiter-main);
    pointer-events: none;
  }

  &:not(.disabled).hovered {
    background-color: transparent;
    color: var(--color-primary-default);
  }
}

.group {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 8px;
}

.groupLabel {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: calc(var(--level) * 6px);
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  cursor: default;
  color: var(--color-text-secondary);
}

.content {
  display: flex;
  max-width: 100%;
  align-items: center;
  gap: 4px;
}

.label {
  display: inline-block;
  height: 100%;
  // flex-shrink: 0;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.remove {
  width: 20px;
}
