.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px 7px;
  border-bottom: 1px solid var(--color-delimiter-main);
}

.monthTitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  color: var(--color-text-main);
}
