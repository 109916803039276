.root {
  position: absolute;
  display: flex;
  width: fit-content;
  height: 40px;
  top: 0;
  left: 0;
  align-items: center;
  padding: 6px 8px;
  border-radius: 6px;
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);
  z-index: 10000;

  .delimiter {
    width: 1px;
    height: 28px;
    margin: 0 8px;
    background-color: var(--color-delimiter-main);
  }

  .group {
    display: flex;
    align-items: center;
    font-size: 18px;

    &.color {
      gap: 8px;
      padding: 0 4px;
    }
  }
}

.symbols {
  display: flex;
  width: 198px;
  flex-wrap: wrap;
  gap: 4px;
}
