.base {
  display: flex;

  & > *:not(:first-child) {
    margin-left: 4px;
  }
}

.shifted {
  display: flex;

  &.mini > div {
    width: 12px;
  }

  &.medium > div {
    width: 16px;
  }

  &.large > div {
    width: 24px;
  }

  & > div:last-child {
    width: auto;
  }
}

.mentioned {
  display: flex;
  flex-wrap: wrap;
}

.mentionOverflow {
  display: block;
  margin: 1px;
  padding: 0 8px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: 6px;
  background-color: var(--color-background-main);
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  cursor: default;
}

.overflow {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-delimiter-main);
  background-color: var(--color-background-secondary);
  cursor: default;
}

.editorHeader {
  display: flex;
  height: 100%;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 8px;
  }

  .avatar {
    position: relative;
    //width: 24px;
    //height: 24px;
  }

  &.mini {
    .overflow {
      border: 1px solid var(--color-secondary-default);
      background-color: var(--color-secondary-default);
      color: var(--color-background-main);
    }
  }

  .avatarBorder {
    position: absolute;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    top: -3px;
    left: -3px;
    /* stylelint-disable-next-line color-no-hex */
    border: 1px solid #eee;
    border-radius: 50%;
    pointer-events: none;
  }
}

.overflowList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.overlayContainer {
  max-height: 200px;
}

.overlayShifted {
  padding: 16px;
  padding-right: 32px;
}

.overflowShifted {
  display: flex;
  align-items: center;
  margin-left: 7px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-main);

  &.mini {
    margin-left: 7px;
  }

  &.medium {
    margin-left: 12px;
  }
}
