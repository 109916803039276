.root {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-delimiter-main);
  border-radius: 50%;
  background-color: var(--color-background-main);
  transition: var(--transition-duration);
  overflow: hidden;
  cursor: pointer;

  &.xmini {
    width: 20px;
    height: 20px;
  }

  &.mini {
    width: 28px;
    height: 28px;
  }

  &.medium {
    width: 32px;
    height: 32px;
  }

  &.large {
    width: 40px;
    height: 40px;
  }

  &:hover {
    border-color: var(--color-input-focus);
  }

  &.focused,
  &.active {
    border-color: var(--color-input-focus);
    outline: none;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  &.xmini {
    width: 20px;
    height: 20px;
  }

  &.mini {
    width: 28px;
    height: 28px;
  }

  &.medium {
    width: 32px;
    height: 32px;
  }

  &.large {
    width: 40px;
    height: 40px;
  }

  &:hover {
    border-color: var(--color-input-focus);
  }
}

.color {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;

  &.xmini {
    width: 14px;
    height: 14px;
  }

  &.mini {
    width: 20px;
    height: 20px;
  }

  &.medium {
    width: 22px;
    height: 22px;
  }

  &.large {
    width: 28px;
    height: 28px;
  }
}

.alphaBackground {
  border-radius: 50%;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><path d="M8 0h8v8H8zM0 8h8v8H0z"/></svg>');
  background-size: 9px;
  inset: 0;
}
