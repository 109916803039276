.root {
  position: absolute;
  display: flex;
  width: 300px;
  top: 0;
  right: 0;
  flex-direction: column;
  border: 2px solid var(--color-delimiter-main);
  overflow: hidden;
}

.absolute {
  max-height: calc(100% - 77px);
  margin: 9px 8px 68px 0;
  box-shadow: var(--box-shadow-default);
  z-index: 1;
}

.static {
  height: 100%;
  border-left: 1px solid var(--color-delimiter-main);
}

.fullHeight {
  height: 100%;
}
