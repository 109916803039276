.root {
  display: flex;
  width: fit-content;
  left: 50%;
  align-items: center;
  padding: 6px;
  padding-left: 12px;
  border-radius: var(--border-radius-main);
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);
  transition: display 0.3s;

  &.fluidHeight {
    height: auto;
    padding: 11px 16px;
    border: 1px solid var(--brand-borderColor);
    box-shadow: none;
    background-color: var(--brand-primaryBackgroundColor);
  }

  .groupControl {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &.color {
    gap: 8px;
    padding: 0 4px;
  }
}

.symbols {
  display: flex;
  width: 198px;
  flex-wrap: wrap;
  gap: 4px;
}

.aiButton {
  color: var(--color-primary-default);
}

.root.aiForm {
  box-shadow: var(--box-shadow-default);
}
