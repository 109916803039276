.root {
  display: -webkit-box;
  max-width: 100%;
  font-family: Inter, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: var(--color-text-main);

  &.pointer {
    cursor: pointer;
  }

  &.fluid {
    width: 100%;
    flex-grow: 1;
  }

  &:empty.placeholder:after {
    content: attr(data-placeholder);
    color: var(--color-text-secondary);
  }
}

.hint {
  color: var(--color-text-secondary);
}

.link {
  text-decoration: underline;
  text-decoration-color: color-mix(in sRGB, currentColor 30%, transparent);
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.heading {
  &.xmini {
    font-size: 16px;
    line-height: 24px;
  }

  &.mini {
    font-size: 20px;
    line-height: 30px;
  }

  &.medium {
    font-size: 24px;
    line-height: 34px;
  }

  &.large {
    font-size: 32px;
    line-height: 40px;
  }
}

.xmini {
  font-size: 11px;
  line-height: 16px;
}

.mini {
  font-size: 12px;
  line-height: 18px;
}

.medium {
  font-size: 13px;
  line-height: 18px;
}

.large {
  font-size: 14px;
  line-height: 18px;
}

.weight-regular {
  font-weight: 400;
}

.weight-medium {
  font-weight: 500;
}

.weight-semi {
  font-weight: 600;
}

.weight-bold {
  font-weight: 700;
}
