.item {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: groupingScale 3s;
  animation-fill-mode: forwards;
  z-index: 10;
}

.label {
  margin: auto;
  word-break: break-word;
}

.mask {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(29, 43, 75, 0.8), rgba(29, 43, 75, 0.8));
}

.icon {
  width: 1em;
  height: 1em;
  background-size: contain;
  font-size: 24px;
}

@keyframes groupingScale {
  0% {
    transform: scale(1) translate(var(--translateX), var(--translateY));
  }

  12% {
    transform: scale(0.8) translate(var(--translateX), var(--translateY));
  }

  80% {
    transform: scale(0.8) translateY(500px);
  }

  100% {
    transform: scale(0.8) translateY(500px);
  }
}
