.static {
  position: static;
  display: flex;
  width: 100%;
  height: 100%;
}

.fixed {
  position: fixed;
  display: flex;
  background-color: var(--color-background-main);
  z-index: 2;
  inset: 0;
}
