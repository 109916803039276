.root {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.content {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  padding: 24px;
}

.reset {
  margin-right: 4px;
}

.rightPlaceholder {
  flex: 1;
}

.reactCropWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.side {
  display: flex;
  min-width: 344px;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  border-left: var(--color-delimiter-main) 1px solid;
}

.title {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.group {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 13px;
}

.fieldLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.label {
  color: var(--color-text-secondary);
}

.field {
  display: flex;
  gap: 8px;

  &.fluid {
    flex: 1;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}
