.controls {
  flex-grow: 0;
  flex-shrink: 0;

  &.inline {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 8px;
    opacity: 0;
  }
}

.play {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 50%;
  background-color: var(--color-primary-default);
  font-size: 32px;
  transition: 0.2s;
  cursor: pointer;
  opacity: 0;
  color: var(--color-primary-default);

  &.full {
    width: 56px;
    height: 56px;
    padding-left: 3px;
    font-size: 40px;
    opacity: 1;
    color: var(--color-background-main);
  }
}

.root {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;

  &.full {
    border: 1px solid var(--color-delimiter-main);
    border-radius: 6px;
    background-color: var(--color-background-main);
  }

  &:hover {
    .controls {
      opacity: 1;
    }

    .play {
      opacity: 1;
    }
  }
}

.wrapIos {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10000;
  inset: 0;
}

.fixSwipe {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}

.video {
  position: relative;
  display: flex;
  max-height: 100%;
  flex-grow: 1;
  overflow: hidden;

  &.full {
    border-radius: 4px;
  }

  video {
    background-color: var(--color-black);
  }
}

.fullscreen {
  margin: 0 !important;
  border: none !important;
  border-radius: 0 !important;
}

.skeleton {
  width: 100%;
  height: 500px;
  /* stylelint-disable-next-line color-no-hex */
  background-color: #f8f9fa;
}

.audio {
  height: 0;

  &.showCC {
    height: 100px;
  }
}

.mask {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.cover {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--color-background-main);
  object-fit: scale-down;
}

video::cue {
  position: relative;
  margin: 0 20px;
  background-color: var(--color-black);
  font-size: 20px;
  line-height: 1.5;
  opacity: 0.8;
  visibility: hidden;
}

.tablet,
.mobile {
  video::cue {
    font-size: 16px;
  }
}

.showCC {
  video::cue {
    visibility: visible;
  }
}

.ccPlayer {
  pointer-events: none;

  video {
    background-color: transparent;
  }
}
