.group {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-style: solid;
  background-size: cover;
  text-align: center;
  cursor: pointer;

  &.card {
    width: 176px;
    height: 176px;
  }
}

.label {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  &.placeholder {
    font-size: 16px;
    color: var(--color-placeholder);
  }
}
