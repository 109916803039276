.slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.track {
  display: flex;
  height: 100%;
  justify-content: center;
}

.item {
  flex: 1 0 100%;
}

.itemInner {
  display: flex;
  height: 100%;
  flex-direction: row;
  padding: 16px;
}
