.root {
  display: flex;
  width: 260px;
  flex-direction: column;
}

.body {
  margin: 18px 16px;
}

.saturation {
  position: relative;
  height: 160px;
}

.hue {
  position: relative;
  height: 12px;
  margin-bottom: 16px;
}

.alpha {
  position: relative;
  height: 12px;
  margin-bottom: 18px;
}

.saturationPointer {
  width: 18px;
  height: 18px;
  border: 3px solid var(--color-white);
  border-radius: 50%;
  box-shadow:
    0 0 1px rgba(0, 0, 0, 0.1),
    0 0 1px rgba(33, 43, 54, 0.2);
  transform: translate(-50%, -50%);
}

.sliderPointer {
  width: 16px;
  height: 16px;
  border: 3px solid var(--color-white);
  border-radius: 50%;
  box-shadow:
    0 0 1px rgba(0, 0, 0, 0.1),
    0 0 1px rgba(33, 43, 54, 0.2);
  transform: translate(-50%, -2px);
}
