.root {
  display: flex;
  width: 100vw;
  height: 94vh;
  max-width: 410px;
  max-height: none;
  flex-direction: column;
  padding: 16px;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: var(--color-background-main);
  cursor: pointer;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.title {
  margin-bottom: 24px;
  text-align: center;
}

.item {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 16px 20px;
  border: 1px solid #eaedf3;
  border-radius: 12px;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  font-size: 14px;

  svg {
    cursor: pointer;
  }
}
