.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: var(--border-radius-main);
}

.title {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
