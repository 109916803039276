.root {
  display: flex;
  width: 100%;
  flex-grow: 1;

  &.notEmpty {
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
  }
}

.avatar {
  display: flex;
  height: 18px;
  align-items: center;
}

.text {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
}

.send {
  display: flex;
  height: 18px;
  align-items: center;
}
