.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.item {
  position: absolute;
  overflow: hidden;
}

.loaderRow {
  position: absolute;
  width: 100%;
  height: 100px;
}

.loader {
  height: 75vh;
}
