.root {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 1px;
}

.wrapper {
  margin-top: 8px;
  border: 1px solid var(--color-delimiter-secondary);
  border-radius: 6px;
  background-color: var(--color-background-secondary);
}

.preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  border-bottom: 1px solid var(--color-delimiter-secondary);
  overflow: hidden;

  &.full {
    height: 200px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .icon {
    font-size: 32px;
    color: var(--color-text-secondary);

    &.active {
      color: var(--color-background-dark);
    }
  }

  &:hover {
    cursor: auto;

    &.empty {
      cursor: pointer;

      .icon {
        color: var(--color-primary-default);
      }
    }
  }
}

.info {
  padding: 16px;
}

.title {
  margin-bottom: 2px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-secondary);

  &.bold {
    font-weight: 500;
    font-size: 13px;
  }

  &.link {
    margin-top: 18px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .buttons {
    display: flex;
    gap: 4px;
  }
}

.label {
  color: var(--color-text-secondary);
}

.editButton {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  -webkit-text-decoration-color: rgb(28, 28, 28, 0.3);
  text-decoration-color: rgb(28, 28, 28, 0.3);
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.infoIcon {
  margin: 0 8px 0 2px;
}
