.block {
  display: flex;
  height: 220px;
  max-height: 220px;
  flex-grow: 1;
  flex-direction: column;
  padding: 2px;
  border-radius: 6px;
}

.blockWrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.item {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.title {
  margin-top: 8px;
}

.name {
  display: flex;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-main);
}

.divider {
  padding: 0 4px;
  font-size: 11px;
  line-height: 7px;
  vertical-align: text-top;
}
