.root {
  flex-grow: 1;

  :global {
    .PhoneInputInput {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 32px;
      min-width: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 5px 8px;
      border: 1px solid var(--color-background-secondary);
      border-radius: 6px;
      background: var(--color-background-secondary);
      background-image: none;
      list-style: none;
      font-variant: tabular-nums;
      font-size: 13px;
      line-height: 1.57;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      -webkit-font-feature-settings: 'tnum', 'tnum';
      font-feature-settings: 'tnum', 'tnum';
      color: var(--color-text-main);
    }

    .PhoneInputInput::placeholder {
      color: var(--color-placeholder);
    }

    .PhoneInputInput:not([disabled]):hover {
      border: 1px solid var(--color-delimiter-main);
      border-right-width: 2px !important;
    }

    .PhoneInputInput:not([disabled]):focus {
      border: 1px solid var(--color-input-focus);
      border-right-width: 2px !important;
      box-shadow: none;
      outline: 0;
      background: var(--color-background-main);
    }

    .PhoneInputCountryIcon {
      color: var(--color-text-main);
    }

    .PhoneInputInternationalIconPhone {
      opacity: 1;
    }
  }
}

.root.error {
  :global {
    .PhoneInputInput:not([disabled]) {
      border: 1px solid var(--color-input-error);
    }
  }
}
