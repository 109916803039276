.root {
  position: relative;
  min-height: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-direction: column;
  font-weight: var(--base-fontWeight);
  font-style: var(--base-fontStyle);
  font-size: var(--base-fontSize);
  font-family: var(--base-fontFamily);
  line-height: var(--base-lineHeight);
  text-align: var(--body-textAlign);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  color: var(--base-color);

  > * {
    width: 100%;
  }

  span::selection,
  .pseudoSelection {
    background: rgb(159, 190, 219);
    pointer-events: none;
  }
}

.editable {
  cursor: text;
}
