.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 0 28px;
}

.file {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fileName {
  border-bottom: 1px solid var(--color-delimiter-secondary);
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
}

.placeholder {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-secondary);
}
