.root {
  --checkbox-width: 30px;

  position: relative;
  display: flex;
  min-height: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: hidden;

  .tableWrapper {
    position: relative;
    height: 100%;
    overflow-y: auto;

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: separate;
    }
  }

  .wrapperPagination {
    margin-top: 32px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-text-secondary);
  }
}

.tableLayout {
  display: flex;
  // height: 100%;
  flex: 0 1 auto;
  flex-direction: column;
  // justify-content: center;
  overflow: hidden;
}

.tableFooter {
  display: flex;
  min-height: 64px;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  background: var(--color-background-main);
  font-size: 12px;
  color: rgba(26, 38, 50, 0.54);
}

.cell,
.cellHeader {
  display: block;
  min-width: 100%;
  padding: 7px 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.loaderRow {
  position: relative;
  width: 100%;
  height: 40px;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.sticky {
  position: sticky;
  left: 0;
  border-right: 1px solid var(--color-delimiter-main);
  background-color: var(--color-background-main);
  z-index: 2;
}

.stickyCheckbox {
  position: sticky;
  left: 0;
  background-color: var(--color-background-main);
  z-index: 3;

  &.stickyCheckbox {
    padding: 0;

    & > * {
      margin-left: 16px;
    }
  }

  & + .sticky {
    left: 38px;
  }
}

.header {
  th {
    position: sticky;
    height: 32px;
    top: 0;
    border-top: 1px solid var(--color-delimiter-main);
    border-right: 1px solid var(--color-delimiter-main);
    border-bottom: 1px solid var(--color-delimiter-main);
    background-color: var(--color-background-main);
    font-weight: 500;
    font-style: normal;
    font-size: 11px;
    line-height: 16px;
    z-index: 4;
    color: var(--color-text-secondary);

    &:not(.checkboxHeader) > * {
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  th:first-child {
    left: 0;
    border-left: none;
    z-index: 5;
  }

  th:last-child {
    border-right: none;
  }

  th:has(+ .noBordersHeader) {
    border-right: none;
  }

  th.checkboxHeader {
    border-right: none;
  }

  th.checkboxHeader + th {
    border-left: none;
  }
}

.body {
  tr {
    align-items: center;

    td {
      height: 48px;
      padding: 0 16px;
      border-bottom: 1px solid var(--color-delimiter-main);
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      line-height: 18px;
    }
  }
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.checkboxHeader {
  width: 30px;
}

.header th.checkboxHeader,
.header th.checkboxHeader + .sticky {
  z-index: 5;
}

.disabledRow {
  color: var(--color-placeholder);
}

.emptyWrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
