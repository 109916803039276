.root {
  position: absolute;
  display: none;
  width: 8px;
  height: 8px;
  background-color: var(--color-background-main);
  z-index: 2;

  &.visible {
    display: block;
  }
}
