.root {
  display: flex;
  width: 562px;
  gap: 8px;
  padding: 6px;
  border-radius: var(--border-radius-main);
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);
}

.buttons {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: 2px;
}
