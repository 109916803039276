.root {
  display: flex;
  height: 100%;
  flex-shrink: 0;
  flex-direction: column;
  gap: 24px;
  // background: linear-gradient(90deg, rgba(255, 255, 255, 1) 97%, rgba(0, 0, 0, 0.05) 100%);background
  border-right: 1px solid var(--color-delimiter-secondary);
  z-index: 1;
}

// .drawer {
//   width: 360px;width
// }

.fluid {
  width: 100%;
}

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  // margin: 32px 32px 0;margin
  margin: 32px 0 0 32px;
}

.positionRight {
  border-left: 1px solid var(--color-delimiter-secondary);
  //   background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(255, 255, 255, 1) 3%);background
}
