.root {
  display: flex;
  align-items: center;
  gap: 16px;
}

.textInfo {
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.contactSales {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;
  inset: 0;
}

.overlay {
  position: absolute;
  background-color: var(--color-background-dark);
  opacity: 0.9;
  z-index: -1;
  inset: 0;
}

.content {
  max-width: 700px;
  flex-grow: 1;
  animation: appear 1s;
  animation-timing-function: cubic-bezier(1, -0.74, 1, -0.75);
  opacity: 1;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
