.item {
  display: flex;
  overflow: hidden;

  .itemIcon {
    display: flex;
  }
}

.iconImage {
  width: 20px;
  height: 20px;
  grid-area: icon;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.itemContent {
  width: 100%;
  flex-grow: 1;
  padding-top: 4px;
  word-break: break-all;
  overflow: hidden;
}

.separator {
  margin: 0 4px;
}
