.editor-element-component {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.editor-element-component-video,
.editor-element-component-splitter,
.editor-element-component-image {
  height: 100%;
}
