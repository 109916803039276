.test-info-list {
  box-sizing: border-box;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.test-info-list li {
  margin-bottom: 24px;
}

.test-info-list li:last-child {
  margin-bottom: 0;
}
