@forward './theme/reset';
// uppy
@forward './theme/uppy.fileportal';
// global
@forward './theme/global';
// fonts
@forward './theme/fonts';

/* stylelint-disable no-invalid-position-at-import-rule */
/* stylelint-disable color-no-hex */
:root {
  --app-height: 100vh;
  --header-height: 72px;
  --second-header-height: 88px;
  --body-padding: 40px;
  --header-modal: 88px;
  --footer-modal: 96px;
  --vertical-indent-modal: 100px;
  --elements-border-radius: 6px;
  --thumb-box-shadow: 0 0 7px rgba(33, 43, 54, 0.2);
  --card-box-shadow: 0 0 15px rgba(105, 119, 135, 0.1);
}

#root {
  height: 100%;
}

[data-whatintent='mouse'] body *:focus,
[data-whatintent='mouse'] body .ant-btn:focus {
  outline: none;
}
