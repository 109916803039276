.root {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.dots {
  position: relative;
  display: flex;
  transition: transform 0.5s ease-in-out;

  &.center {
    justify-content: center;
  }
}

.numbered {
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-main);
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;

  &.left {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 8%);
  }

  &.right {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 92%, rgba(255, 255, 255, 0.8) 100%);
  }

  &.full {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 8%,
      rgba(255, 255, 255, 0) 92%,
      rgba(255, 255, 255, 0.8) 100%
    );
  }
}
