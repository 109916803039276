.column {
  position: relative;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-direction: column;
}

@media print {
  .column {
    display: block !important;
  }
}
