.root {
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    width: 22px;
    height: 22px;
    border: 1px solid var(--color-delimiter-main);
    border-radius: 6px;
  }
}

.filter {
  display: flex;
  gap: 8px;
}
