.root {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 0;
  border: 1px solid transparent;
  border-radius: var(--border-radius-main);
  font-size: 13px;
  cursor: default;
  color: var(--color-text-main);

  &:hover {
    .addition.hideIcon {
      display: flex;
    }
  }

  &.base {
    background: var(--color-background-secondary);

    &:hover {
      border: 1px solid var(--color-delimiter-main);
    }

    &.focus {
      border: 1px solid var(--color-input-focus);
      outline: none;
      background: var(--color-background-main);
    }
  }

  input {
    width: 100%;
    flex-grow: 1;
    padding: 0 8px;
    border: none;
    background-color: transparent;

    &::placeholder {
      color: var(--color-placeholder);
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--color-background-secondary) inset !important;
    }
  }

  &.ghost input {
    padding-left: 0;
  }

  &.mini {
    height: 28px;
  }

  &.medium {
    height: 32px;
  }

  &.large {
    height: 40px;
  }

  &.disabled {
    border: 1px solid transparent;
    background: var(--color-disabled);
    cursor: not-allowed;
    color: var(--color-text-secondary);

    input {
      cursor: not-allowed;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--color-disabled) inset !important;
    }
  }

  &.base.error {
    border: 1px solid var(--color-input-error);
    background: var(--color-background-main);
  }

  &.hasPrefix {
    padding-left: 0;
  }

  &.hasPostfix {
    padding-right: 0;
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px var(--color-background-main) inset !important;
  }
}

.addition {
  display: flex;
  color: var(--color-text-secondary);

  /* stylelint-disable-next-line no-descending-specificity */
  &.hideIcon {
    position: absolute;
    display: none;
    right: -5px;
  }

  &:first-child {
    padding-left: 8px;
  }

  &:last-child {
    padding-right: 8px;
  }
}
