.root {
  border-radius: 50%;
  object-fit: cover;
}

.disabled {
  cursor: not-allowed;
}

.active {
  border: 1px solid var(--color-white);
  outline: 1px solid var(--color-primary-default);
}

.icon.disabled {
  display: flex;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: var(--color-placeholder);
  font-size: 16px;
  color: var(--color-background-main);
}

.stub {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 11px;
  color: var(--color-white);
}

.xmini {
  width: 16px;
  height: 16px;
  min-width: 16px;
  font-size: 8px;
  line-height: 16px;
}

.mini {
  width: 16px;
  height: 16px;
  min-width: 16px;
  font-size: 8px;
  line-height: 16px;
}

.medium {
  width: 24px;
  height: 24px;
  min-width: 24px;
  font-size: 12px;
  line-height: 24px;
}

.large {
  width: 36px;
  height: 36px;
  min-width: 36px;
  font-size: 18px;
  line-height: 36px;
}

.info {
  cursor: default;
}
