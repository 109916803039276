.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  border-radius: 6px;
}

.title {
  margin-bottom: 24px;
  text-align: center;
  white-space: pre-wrap;
}

.text {
  margin-bottom: 16px;
  text-align: center;
  white-space: pre-wrap;
}

.button {
  margin-top: 32px;
}

.demo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  background-color: var(--color-text-main);
  opacity: 0.9;

  &.disabled {
    opacity: 0.5;
  }

  & > div {
    max-width: 640px;
  }
}
