.root {
  position: absolute;
  display: flex;
  width: 343px;
  max-width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  box-sizing: border-box;
  padding-right: 4px;
  padding-left: 10px;
  border-radius: 6px;
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);
  z-index: 10000;
}

.input {
  flex-grow: 1;
}

.controls {
  display: flex;
  margin-left: 6px;
}
