.buttons {
  display: flex;
  overflow: hidden;

  button {
    width: calc(50% - 1px);
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
    margin: 6px 0;
  }
}
