.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 24px;
  margin: 32px 32px 0;
}

.buttonBack {
  display: flex;
  height: 100%;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: var(--color-primary-default);
  }
}

.headerTitleWrapper {
  display: flex;
  height: 100%;
  max-width: 90%;
  align-items: center;
  margin-left: 14px;
}

.searchWrapper {
  margin-bottom: 24px;
}

.progress {
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 17px;
}

.reverseProgress {
  flex-direction: row-reverse;
}

.headerTitle {
  display: -webkit-box;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.defaultStructure {
  margin-top: 0;
  margin-bottom: 4px;
}

.content {
  margin-bottom: 120px;
}

.row {
  display: flex;
  margin-right: 32px;
  margin-bottom: 24px;
}

.resetOffset {
  height: 100%;
  margin-right: 0;
}
