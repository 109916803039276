.root {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 1px solid transparent;
}

.filesOnDragModal {
  border: 1px dashed var(--color-primary-default);
}

.help {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 6px;
  text-align: center;
  z-index: 2;
}

.helpText {
  width: 196px;
  height: 32px;
  padding: 6px;
  border-radius: 6px;
  background-color: var(--color-text-main);
  font-size: 13px;
  line-height: 18px;
  color: var(--color-white);
}
