.root {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: var(--color-text-secondary);
}

.xmini {
  height: 18px;
}

.mini {
  height: 26px;
}

.medium {
  height: 30px;
}

.large {
  height: 38px;
  padding-right: 4px;
}
