.root {
  position: relative;
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  border-radius: var(--border-radius-main);
  transition: var(--transition-duration) ease-in-out;

  &[data-focus-visible='on'] {
    outline: 2px solid var(--color-input-focus);
    outline-offset: 2px;
  }

  &.fluid {
    width: 100%;
  }

  &.inputable {
    cursor: text;
  }

  &.xmini {
    min-height: 20px;
  }

  &.mini {
    min-height: 28px;
  }

  &.medium {
    min-height: 32px;
  }

  &.large {
    min-height: 40px;
  }
}

.root.base {
  border: 1px solid transparent;
  background-color: var(--color-background-secondary);

  &:hover {
    border: 1px solid var(--color-delimiter-main);
  }

  &.focus[data-focus-visible='off'] {
    border: 1px solid var(--color-input-focus);
    background-color: var(--color-background-main);
  }

  // [class] - hack for high specificity and override focus-visible
  &.error[class] {
    border: 1px solid var(--color-input-error);
  }

  &.disabled[class] {
    border: 1px solid transparent;
    outline: none;
    background: var(--color-disabled);
    cursor: not-allowed;
    color: var(--color-text-secondary);
  }
}

.root.ghost {
  padding-left: 0;
  border: 1px solid transparent;

  &.disabled[class] {
    border: 1px solid transparent;
    outline: none;
    background: var(--color-disabled);
    cursor: not-allowed;
    color: var(--color-text-secondary);
  }
}

.value {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow: hidden;

  &.xmini {
    gap: 2px;

    &.base {
      padding: 0 1px;
    }
  }

  &.mini {
    gap: 4px;
    padding: 1px;

    &.base {
      padding: 1px;
    }
  }

  &.medium {
    gap: 4px;
    padding: 2px 1px;

    &.base {
      padding: 2px;
    }
  }

  &.large {
    gap: 4px;
    padding: 4px 1px;

    &.base {
      padding: 4px;
    }
  }
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  overflow: hidden;
  color: var(--color-placeholder);

  &.base {
    padding-left: 7px;
  }

  &.xmini {
    font-size: 12px;
    line-height: 18px;
  }

  &.mini {
    font-size: 13px;
    line-height: 26px;
  }

  &.medium {
    font-size: 13px;
    line-height: 30px;
  }

  &.large {
    font-size: 13px;
    line-height: 38px;

    &.base {
      padding-left: 11px;
    }
  }
}

.single {
  align-items: center;
}
