.tableControl {
  margin: 0;
  padding: 0;
  border-radius: 6px;
  box-shadow:
    0 2px 10px 0 rgba(0, 0, 0, 0.1),
    0 0 2px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--color-background-main);
  cursor: move;
  overflow: hidden;
  z-index: 1;
}

.col {
  position: absolute;
  top: -4px;
  left: calc(50% - 9px);
  padding: 4px 0;
  transform: rotate(90deg);
}

.row {
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  padding: 4px 0;

  & > span {
    transform: rotate(-0.25turn);
  }
}

.row,
.col {
  font-size: 15px;
  line-height: 15px;
}

.hidden {
  display: none;
}

.menuOpen svg > * {
  fill: var(--color-input-focus);
}
