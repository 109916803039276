.actions {
  display: flex;
  justify-content: space-between;
  gap: 4px;

  &.hovered {
    opacity: 0;

    button {
      border: none;
      background-color: transparent;
    }
  }
}

.root {
  &.hovered:last-child,
  &.padding:last-child {
    margin-bottom: 16px;
    border-bottom: 1px solid var(--color-delimiter-main);
  }

  .item {
    user-select: none;

    &.padding {
      padding: 16px 16px 0;
      border-top: 1px solid var(--color-delimiter-main);
    }

    &.bordered {
      margin-bottom: 16px;
      padding: 16px 16px 0;
      border: 1px solid var(--color-delimiter-main);
      border-radius: 8px;
      box-shadow: 0 0 15px rgba(105, 119, 135, 0.1);
      background-color: var(--color-background-main);
    }

    &.clear {
      margin-bottom: 16px;
      padding: 16px 0 0;
      border-bottom: 1px solid var(--color-delimiter-main);
    }

    &.dragging {
      width: calc(100% + 32px);
      box-shadow: 0 0 15px rgba(105, 119, 135, 0.1);
      background-color: var(--color-background-main);
    }

    &.hovered {
      padding: 16px 0 0;
      border-top: 1px solid var(--color-delimiter-main);

      &:hover {
        background-color: var(--color-primary-hover);

        .actions {
          opacity: 1;
        }
      }
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
}

.title {
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: 13px;

  &.collapse {
    color: var(--color-text-secondary);
  }
}

.dragIcon {
  display: flex;
  margin-right: 8px;
}

.active {
  background-color: var(--color-primary-hover);
}

.fields {
  padding-bottom: 16px;

  &.collapse {
    flex-grow: 1;
    margin: 16px 0;
    border-bottom: 1px solid var(--color-delimiter-main);
  }

  &.horizontal {
    display: flex;
    gap: 16px;
  }
}

.collapseDelete {
  right: 20px;
  z-index: 1;
}
