.test-result-el {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  word-break: break-all;
  overflow: hidden;
}

.test-result-el__content {
  position: relative;
  display: flex;
  align-items: center;
}

.test-result-el_position_top {
  flex-direction: column;
}

.test-result-el_position_right {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.test-result-el_position_bottom {
  flex-direction: column-reverse;
}

.test-result-el__result-text {
  font-weight: 500;
  font-style: normal;
  font-size: 24px;

  // line-height: 30px;
  word-break: break-word;
}

.test-result-el__result-text_position_top {
  margin-top: 24px;
  text-align: center;
}

.test-result-el__result-text_position_left {
  margin-left: 24px;
  text-align: left;
}

.test-result-el__result-text_position_bottom {
  margin-bottom: 24px;
  text-align: center;
}

.test-result-el__result-text_position_right {
  margin-right: 24px;
  text-align: right;
}

.test-result-el__feedback-text {
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1em;
  color: var(--color-text-secondary);
}

.test-result-el .ant-progress-text {
  font-weight: var(--textPercents-fontWeight);
  font-style: var(--textPercents-fontStyle);
  font-size: var(--textPercents-fontSize);
  font-family: var(--textPercents-fontFamily);
  color: var(--textPercents-color);
}

.test-result-el .ant-progress-status-success .ant-progress-text {
  color: var(--textPercents-color);
}

.test-result-el__replay {
  display: flex;
  justify-content: center;
}
