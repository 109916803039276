.root {
  width: 290px;
  height: 380px;
  background-color: transparent;
  background-color: var(--color-background-main);
  perspective: 1000px;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.6s ease;
  will-change: backface-visibility;
  transform-style: preserve-3d;

  // animations ---------------------------------
  &.diagonalFlip {
    transition: transform 0.8s ease;
    transform-style: preserve-3d;

    &.active {
      transform: rotate3d(-1, 1, 0, 180deg);
    }
  }

  &.horizontalFlip {
    &.active {
      transform: rotateY(180deg);
    }

    &:not(.pauseAnimation) {
      animation: cardFlip 0.6s forwards linear;

      &.active {
        animation: cardUnFlip 0.6s forwards linear;
      }
    }
  }

  &.verticalFlip {
    &.active {
      transform: rotateX(180deg);
    }
  }

  &.fadeEffect {
    &.active {
      opacity: 1;
    }
  }

  &.pauseAnimation {
    transition: all 0ms ease;

    &.active {
      transition: all 0ms ease;
    }
  }
  // -----------------------------------
}

.icon {
  position: absolute;
  display: flex;
  width: 40px;
  height: 40px;
  top: 16px;
  left: 16px;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 24px;
  backface-visibility: hidden;
}

.fill {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media print {
  .root {
    display: block;
    margin-bottom: 0.1cm;
  }

  .card {
    display: flex;
    break-inside: avoid;
    align-items: center;
    justify-content: center;
  }
}

@keyframes cardFlip {
  0% {
    transform: rotateZ(0deg) rotateY(180deg);
  }

  50% {
    transform: rotateZ(-10deg) rotateY(90deg);
  }

  100% {
    transform: rotateZ(0deg) rotateY(0deg);
  }
}

@keyframes cardUnFlip {
  0% {
    transform: rotateZ(0deg) rotateY(0deg);
  }

  50% {
    transform: rotateZ(-10deg) rotateY(90deg);
  }

  100% {
    transform: rotateZ(0deg) rotateY(180deg);
  }
}

// book animation --------------------------------
.book {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  perspective: 1000px;
}

.left,
.right,
.leftFull,
.rightFull {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 2s;
  overflow: hidden;
}

.right,
.rightFull {
  right: 0;
  left: auto;

  & *:first-child {
    right: 0;
    left: auto;
  }

  .content {
    right: 0;
    left: auto;
  }
}

.left,
.right {
  z-index: 3;
}

.leftFull,
.rightFull {
  z-index: 2;
}

.leftFull {
  transform: rotateY(180deg);
  transform-origin: 100% 0;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.right {
  transform: rotateY(0deg);
  transform-origin: 0 0;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.rightFull.transparentFront {
  opacity: 0;
}

.active {
  .right,
  .leftFull {
    z-index: 4;
  }

  .right {
    transform: rotateY(-180deg);
  }

  .leftFull {
    transform: rotateY(0deg);
  }

  .left.transparentBack {
    opacity: 0;
  }

  .rightFull.transparentFront {
    opacity: 1;
  }
}

//-------------------------------------------------
