.root {
  width: 115px;

  &.fluid {
    width: 100%;
  }
}

.calendar {
  display: flex;
  border: none;
  border-radius: var(--border-radius-main);
  background-color: var(--color-background-main);

  :global(.react-datepicker__header) {
    padding: 0;
    border-bottom: none;
    background-color: transparent;

    :global(.react-datepicker__day-names) {
      padding: 16px 0 8px;

      :global(.react-datepicker__day-name) {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: var(--color-text-secondary);
      }
    }
  }

  :global(.react-datepicker__day),
  :global(.react-datepicker__day--keyboard-selected),
  :global(.react-datepicker__year-text--keyboard-selected),
  :global(.react-datepicker__month-text--keyboard-selected),
  :global(.react-datepicker__quarter-text--keyboard-selected) {
    background-color: transparent;
    color: var(--color-text-main);
  }

  :global(.react-datepicker__day--outside-month) {
    color: var(--color-disabled);
  }
}
