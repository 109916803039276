.horizontal {
  border: none;
  border-bottom: 1px solid var(--color-delimiter-main);

  &.dark {
    border-bottom: 1px solid var(--color-delimiter-dark);
  }

  &.xmini {
    margin: 8px 0;
  }

  &.mini {
    margin: 12px 0;
  }

  &.medium {
    margin: 16px 0;
  }

  &.large {
    margin: 24px 0;
  }

  &.free {
    margin: 0;
  }
}

.vertical {
  height: auto;
  border: none;
  border-right: 1px solid var(--color-delimiter-main);

  &.dark {
    border-right: 1px solid var(--color-delimiter-dark);
  }

  &.xmini {
    height: 16px;
    margin: 0 8px;
  }

  &.mini {
    height: 28px;
    margin: 0 12px;
  }

  &.medium {
    height: 36px;
    margin: 0 16px;
  }
  // &.large {}
  &.free {
    margin: 0;
  }
}

.dash {
  width: 14px;
  margin: 0 12px;
  border-color: var(--color-text-secondary);
  border-bottom: 1px solid var(--color-delimiter-main);
}
