.arrows {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 1em;
  height: 1em;
  margin: 0;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 24px;
}
