.wrapper {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
}

.shell {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
}

.zeroLineHeight {
  line-height: 0;
}

.noClick {
  pointer-events: none;
}

.disableClicks {
  position: absolute;
  z-index: 2;
  inset: 0;
}

.highlighter {
  position: absolute;
  display: block;
  top: 7px;
  left: 7px;
  box-sizing: border-box;
  border-radius: 100%;
  font-weight: 900;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  color: var(--color-secondary-default);
}

@media print {
  .wrapper,
  .shell {
    display: block !important;
  }
}
