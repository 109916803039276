.root {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-secondary);
}

.divider {
  margin: 0 3px;
  font-weight: 600;
}
