.root {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 6px 4px;
  border: 1px solid var(--color-background-secondary);
  border-radius: 6px;
  background-color: var(--color-background-secondary);
  color: var(--color-text-main);

  input {
    width: 100%;
    border: none;
    background: transparent;
    color: var(--color-text-main);

    &::placeholder {
      color: var(--color-placeholder);
    }

    &:focus {
      outline: none;
    }
  }

  &.xmini,
  &.mini {
    height: 28px;
  }

  &.medium {
    height: 36px;
  }

  &:hover {
    border: 1px solid var(--color-delimiter-main);
  }

  &.focus {
    border: 1px solid var(--color-input-focus);
    outline: none;
    background: var(--color-background-main);
  }

  &.error {
    border-color: var(--color-input-error);
  }

  &.disabled {
    border: 1px solid transparent;
    background: var(--color-disabled);
    cursor: not-allowed;
    color: var(--color-text-secondary);

    input {
      cursor: not-allowed;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--color-disabled) inset !important;
    }
  }
}
