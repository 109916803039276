.root {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.seek {
  position: absolute;
  width: 100%;
  bottom: 8px;
  padding: 0 10px;
}

.emptyIcon {
  font-size: 32px;
  color: var(--color-placeholder);
}
