.scalableContainer {
  transform-origin: 0 0;
}

.render {
  position: relative;
  pointer-events: none;
  overflow: hidden;
}

.additionInfo {
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  z-index: 1;

  .left {
    display: flex;
    font-size: 16px;
  }

  .right {
    display: flex;
  }
}

.users {
  position: absolute;
  bottom: 4px;
  left: 4px;
  z-index: 1;
}
