.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--color-text-secondary);
}

.active {
  color: var(--color-text-main);
}

.breadcrumbItem {
  max-width: 300px;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;

  &:hover:not(.active) {
    color: var(--color-primary-default);
  }

  &.active {
    cursor: text;
  }
}

.menuOption {
  display: flex;
  align-items: center;
  gap: 8px;
}
