.root {
  display: flex;
  width: 300px;
  max-height: 614px;
  flex-direction: column;
  background-color: var(--color-background-main);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;
  padding: 8px 0;
  border-bottom: 1px solid var(--color-delimiter-main);
}

.content > :not(:last-child) {
  border-bottom: 1px solid var(--color-delimiter-main);
}

.input {
  margin: 0 16px;
  padding: 21px 0;
  border-top: 1px solid var(--color-delimiter-main);
}
