.root {
  display: flex;
  height: 64px;
  align-items: center;
  border-top: 1px solid var(--brand-borderColor);
  background-color: var(--brand-primaryBackgroundColor);
  font-size: 16px;
  color: var(--brand-bodyFontColor);
}

.progress {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 0 32px;
}

.progressLabel {
  margin-left: 16px;
  white-space: nowrap;
}

.tryCount {
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0 32px;
  border-left: 1px solid var(--brand-borderColor);
  line-height: 18px;
}
