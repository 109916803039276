.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.horizontal {
  flex-direction: row;
}

.vertical {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
