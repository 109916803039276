.root {
  display: flex;
  min-width: 100px;
  max-width: 300px;
  max-height: 424px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.inner {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.content {
  z-index: 1000;

  &.mobile {
    order: 1;
  }
}

.title {
  margin-bottom: 8px;

  &.mobile {
    order: 0;
  }
}

.image {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 16px;

  img {
    max-width: 100%;
    max-height: 250px;
  }

  &.mobile {
    order: 1;
  }
}

.description {
  font-size: 14px;
  line-height: 24px;
  white-space: pre-wrap;
  color: var(--textTitle-color);

  &.mobile {
    order: 2;
  }
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 14px;

  &.mobile {
    justify-content: flex-end;
    margin: 0 0 24px;
  }
}

.disabled {
  cursor: auto;
  opacity: 0.5;
}

.footer {
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: center;
}

.arrows {
  cursor: pointer;
}

.imageWrapper {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
