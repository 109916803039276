.root {
  display: flex;
}

.info {
  flex-grow: 1;
  margin-left: 16px;
}

.titleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-main);
}

.description {
  margin-bottom: 8px;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-secondary);
}
