.root {
  display: flex;
  width: 220px;
  flex-direction: column;
  padding-top: 10px;
  overflow-y: scroll;

  .tabs {
    margin-bottom: 48px;
  }

  .menuItem {
    display: flex;
    height: 34px;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;

    &.active {
      border-radius: 6px;
      background-color: var(--color-background-secondary);
    }

    &:hover {
      color: var(--color-primary-default);
    }
  }

  .tag {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }

  .hint {
    margin-bottom: 10px;
    padding-left: 12px;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-text-secondary);
  }
}
