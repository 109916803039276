.root {
  display: flex;
  width: 100%;
}

.input {
  display: none;
}

.label {
  padding: 8px 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
}
