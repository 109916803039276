.wrapper {
  position: relative;
  width: max-content;
  max-width: 100%;
  flex-direction: column;
  box-sizing: border-box;
}

.table {
  box-sizing: border-box;
  border: 1px solid var(--color-delimiter-main);
  border-collapse: collapse;
  table-layout: fixed;
}

.tableContainer {
  position: relative;
  display: flex;
  box-sizing: border-box;

  &.center {
    justify-content: center;
  }
}

.preview {
  background-color: var(--color-background-main);
  opacity: 0.9;
}

.selectedArea {
  position: absolute;
  border: 1px solid var(--color-input-focus);
  pointer-events: none;
}

.pdf {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;

  table {
    transform-origin: top left;
  }
}
