.root {
  display: flex;
  width: 100%;
  max-width: 100%;
  margin: 0;
  line-height: 1.2;
  white-space: pre-wrap;
  overflow: hidden;
}

.td,
.th {
  position: relative;
  border: 1px solid var(--color-delimiter-main);
  text-align: start;
  cursor: text;
  vertical-align: top;
}

.span {
  display: inline-block;
  width: 100%;
  height: 100%;
  min-height: 1rem;
  word-break: break-word;
}

.defaultTitleContent {
  flex-grow: 1;
}
