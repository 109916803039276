// @import './vars';

:root[data-theme='dark'] {
  // PRIMARY
  --color-primary-default: var(--color-primary-600);
  --color-primary-active: var(--color-primary-500);
  --color-primary-hover: var(--color-primary-700);
  --color-primary-selected: var(--color-primary-800);
  // SECONDARY
  --color-secondary-default: var(--color-secondary-500);
  // BACKGROUND
  --color-background-main: #2c2c2c;
  --color-background-secondary: var(--color-neutral-dark-100);
  --color-background-dark: var(--color-neutral-dark-100);
  // DELIMITER
  --color-delimiter-main: var(--color-neutral-dark-200);
  --color-delimiter-secondary: var(--color-neutral-dark-300);
  --color-delimiter-dark: #3f3f3f; // TODO: to palette
  // TEXT
  --color-text-main: var(--color-neutral-dark-600);
  --color-text-secondary: var(--color-neutral-dark-500);
  --color-link: var(--color-neutral-dark-600);
  // PLACEHOLDER
  --color-placeholder: var(--color-neutral-dark-400);
  // DISABLED
  --color-disabled: var(--color-neutral-dark-300);
  // INPUT
  --color-input-focus: var(--color-primary-400);
  --color-input-error: #f00;

  // SHADOW
  --box-shadow-default: 0 0 0 1px rgba(255, 255, 255, 0.1);
  --box-shadow-small: 0 0 0 1px rgba(255, 255, 255, 0.1);
}
