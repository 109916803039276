.row {
  position: relative;
  display: flex;
}

.controls {
  position: absolute;
  display: block;
  box-shadow: inset 0 0 0 1px var(--color-secondary-default);
  z-index: 1;
  inset: -1px 0 0 -1px;
}

.label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 4px;
  background-color: var(--color-secondary-default);
  font-size: 11px;
  transform: translate(0, -100%);
  color: var(--color-background-main);
}

.fillMode {
  pointer-events: none;
}
