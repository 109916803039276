.root {
  --verticalPadding: 8px;

  position: relative;
  display: flex;

  &.fluid {
    width: 100%;
    flex-grow: 1;
  }

  &.additionRow {
    flex-direction: column;
  }
}

.textarea {
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 13px;
  line-height: 18px;
  resize: none;
  color: var(--color-text-main);

  &.base {
    background: var(--color-background-secondary);
  }

  &.base:hover {
    border: 1px solid var(--color-delimiter-main);
  }

  &.base:focus {
    border: 1px solid var(--color-input-focus);
    outline: none;
    background: var(--color-background-main);
  }

  &.ghost {
    background: var(--color-background-main);
  }

  &.ghost.mini {
    padding: var(--verticalPadding) 0;
  }

  &.ghost.medium {
    padding: var(--verticalPadding) 0;
  }

  &.mini {
    padding: var(--verticalPadding) 8px;
  }

  &.medium {
    padding: var(--verticalPadding) 12px;
  }

  &::placeholder {
    color: var(--color-placeholder);
  }

  &.base.error {
    border: 1px solid var(--color-input-error);
  }

  &.disabled {
    border: 1px solid transparent;
    background: var(--color-disabled);
    cursor: not-allowed;
    color: var(--color-placeholder);

    &::placeholder {
      color: var(--color-text-secondary);
    }
  }

  &.hasPostfix {
    padding-right: 27px;
  }
}

.postfix {
  position: absolute;
  display: flex;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);

  &.additionRow {
    position: static;
    display: flex;
    justify-content: flex-end;
    transform: none;
  }
}
