.root {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: var(--border-radius-main);
  color: var(--color-text-main);
}

.icon {
  display: flex;
  align-self: flex-start;
}

.info {
  background: var(--color-blue-alpha);

  .icon {
    color: var(--color-blue);
  }
}

.error {
  background: var(--color-red-alpha);

  .icon {
    color: var(--color-red);
  }
}

.success {
  background: var(--color-green-alpha);

  .icon {
    color: var(--color-green);
  }
}

.warning {
  background: var(--color-yellow-alpha);

  .icon {
    color: var(--color-yellow);
  }
}

.content {
  flex: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
