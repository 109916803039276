.answer {
  position: absolute;
  display: flex;
  width: 220px;
  height: 220px;
  left: 50%;
  align-items: center;
  justify-content: center;
  background-size: cover;
  text-align: center;
  word-break: break-word;
  transform: translateX(-50%);
  overflow: scroll;
  z-index: 0;

  &.placeholder {
    font-size: 16px;
    color: var(--color-placeholder);
  }
}
