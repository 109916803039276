.root {
  position: relative;
  display: flex;
  width: 100%;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: var(--brand-primaryBackgroundColor);

  .editorBlockLink {
    position: absolute;
    display: flex;
    width: 32px;
    height: 32px;
    top: 10px;
    right: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 20px;
    transition: opacity 200ms;
    pointer-events: none;
    opacity: 0;
    z-index: 10;
    color: var(--color-placeholder);

    &:hover {
      background-color: var(--color-background-secondary);
      color: var(--color-placeholder);
    }
  }

  &:hover {
    .editorBlockLink {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.emptyBlock {
  height: 100%;
}

.block {
  position: relative;
  width: 100%;
  justify-content: center;

  &:hover {
    .editorBlockLink {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.footerNav {
  width: 100%;
}

.displayErrorWrapper {
  padding-top: 0;
}
