.root {
  position: relative;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-background-main);
  border-radius: 50%;
  box-shadow: var(--box-shadow-small);
  background-color: var(--color-background-main);
  transition: border var(--transition-duration);
  cursor: pointer;
  user-select: none;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color var(--transition-duration);
}

.count {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-primary-default);
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  transition:
    background-color,
    color var(--transition-duration);
  color: var(--color-white);
}

.active {
  border: 1px solid var(--color-primary-default);
}

.resolved {
  opacity: 0.5;
}

.newDot {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 3px;
  right: 3px;
  border: 1px solid var(--color-background-main);
  border-radius: 50%;
  background-color: var(--color-primary-default);
}
