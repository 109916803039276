.wrapper {
  overflow: hidden;
}

.root {
  display: flex;
  align-items: baseline;
  column-gap: 16px;
  padding: 20px 24px 24px;

  &.mobile {
    flex-direction: column;
  }
}

.icon {
  width: 32px;
  flex-shrink: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(2px);
}

.demo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
