.header {
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

.breadcrumbs {
  display: flex;
}

.stepper {
  display: flex;
  height: 80px;
  justify-content: center;
  padding: 24px;
  border-bottom: 1px solid var(--color-delimiter-secondary);

  &.disabled {
    background-color: var(--color-primary-selected);
    pointer-events: none;
    opacity: 0.5;
  }
}

.body {
  display: flex;
  height: 100%;
  gap: 20px;
  padding: 0 32px 0 20px;
  overflow: hidden;

  &.extraPadding {
    padding-left: 32px;
  }
}

.filter {
  display: flex;
  gap: 8px;
}

.content {
  flex: 1;
  overflow-y: scroll;
}
