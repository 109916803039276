.vertical {
  display: flex;
  flex-direction: column;
}

.horizontal {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(1px, auto));
  gap: 24px;
}

.fluid {
  flex-grow: 1;
}
