.element {
  flex: 1;
  box-sizing: border-box;
  border-radius: 16px;
}

.elementRoot {
  height: 100%;
  min-width: 32px;
  min-height: 32px;
  padding: 32px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
