.root {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.input {
  width: 100%;
  height: 8px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    width: 0;
    height: 0;
    -webkit-appearance: none;
  }
}

.track {
  position: absolute;
  width: 100%;
  height: 4px;
  top: 50%;
  border-radius: 2px;
  background-color: var(--color-background-secondary);
  transform: translateY(-50%);
  pointer-events: none;
  overflow: hidden;

  &.preview {
    background-color: var(--color-disabled);
  }
}

.loaded {
  position: absolute;
  height: 100%;
  background-color: var(--color-primary-default);
  opacity: 0.25;
}

.played {
  position: absolute;
  height: 100%;
  background-color: var(--color-primary-default);
}

.points {
  position: absolute;
  width: 100%;
  height: 100%;
}

.point {
  position: absolute;
  width: 2px;
  height: 100%;
  transform: translateX(-50%);
}
