.root {
  --slider-handle-line-width: 4px;
  --slider-handle-size-sm: 16px;
  --slider-handle-size-md: 20px;
  --motion-duration: 0.2s;
  --border-radius: 2px;
  --slider-rail-size-sm: 2px;
  --slider-rail-size-md: 4px;

  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;

  .inputWrapper {
    width: 64px;
    flex: 0 1 auto;
  }

  .sliderWrapper {
    display: flex;
    flex-grow: 1;
    margin: 0 4px;
    padding: 8px 0;
    cursor: pointer;
  }

  .slider {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    gap: 6px;
    cursor: pointer;
    color: var(--color-text);
  }

  .sliderRail {
    position: absolute;
    width: 100%;
    height: var(--slider-rail-size-sm);
    border-radius: var(--border-radius);
    background-color: var(--color-delimiter-secondary);
    transition: background-color var(--motion-duration);
  }

  .sliderStep {
    width: 100%;
    height: var(--slider-rail-size-sm);
    top: var(--slider-rail-size-sm);
  }

  .sliderTrack {
    position: absolute;
    height: var(--slider-rail-size-sm);
    left: 0;
    border-radius: var(--border-radius);
    background-color: var(--color-primary-default);
    transition: background-color var(--motion-duration);
  }

  .sliderHandle {
    position: absolute;
    width: var(--slider-handle-size-sm);
    height: var(--slider-handle-size-sm);
    outline: none;
    transform: translateX(-50%);

    &:before {
      content: '';
      position: absolute;
      width: calc(var(--slider-handle-size-sm) + var(--slider-handle-line-width));
      height: calc(var(--slider-handle-size-sm) + var(--slider-handle-line-width));
      background-color: transparent;
    }

    &:after {
      content: '';
      position: absolute;
      width: var(--slider-handle-size-sm);
      height: var(--slider-handle-size-sm);
      border-radius: 50%;
      box-shadow: var(--box-shadow-default);
      background-color: var(--color-background-main);
      transition: var(--motion-duration);
      cursor: pointer;
    }
  }

  &.xmini,
  &.mini {
    height: 28px;
  }

  &.medium,
  &.large {
    height: 36px;
    gap: 16px;

    .sliderRail {
      height: var(--slider-rail-size-md);
    }

    .sliderStep {
      height: var(--slider-rail-size-md);
      top: var(--slider-rail-size-md);
    }

    .sliderTrack {
      height: var(--slider-rail-size-md);
    }

    .sliderHandle {
      width: var(--slider-handle-size-md);
      height: var(--slider-handle-size-md);

      &:before {
        width: calc(var(--slider-handle-size-md) + var(--slider-handle-line-width));
        height: calc(var(--slider-handle-size-md) + var(--slider-handle-line-width));
      }

      &:after {
        width: var(--slider-handle-size-md);
        height: var(--slider-handle-size-md);
      }
    }
  }

  &.disabled {
    .slider,
    .sliderWrapper,
    .sliderRail,
    .sliderTrack,
    .sliderHandle {
      cursor: not-allowed;
    }

    .sliderTrack {
      background-color: var(--color-delimiter-secondary);
    }

    .sliderHandle {
      &:after {
        background-color: var(--color-delimiter-main);
        cursor: not-allowed;
      }
    }
  }
}

.sliderWrapper:focus-visible .sliderHandle:after {
  outline: 2px solid var(--color-input-focus);
}
