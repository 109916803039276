.root {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  inset: 0;
}

.measure {
  position: absolute;
  pointer-events: none;
  inset: 0;
}

.portal {
  position: absolute;
  pointer-events: all;
}

.children {
  display: flex;
}
