.root {
  position: relative;
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;

  .badge {
    position: absolute;
    display: flex;
    border-radius: 50%;
    background-color: var(--color-primary-active);
    font-size: 12px;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: var(--color-background-main);
  }

  .left {
    transform: translate(-100%);
  }

  .right {
    transform: translate(100%);
  }

  .mini {
    height: 16px;
    font-size: 11px;
  }

  .medium {
    height: 20px;
    min-width: 20px;
    font-size: 12px;
  }

  .count {
    &.mini {
      height: 16px;
      min-width: 16px;
      line-height: 16px;
    }

    &.medium {
      height: 20px;
      min-width: 20px;
      line-height: 20px;
    }
  }

  .dot {
    width: 4px;
    height: 4px;

    .count {
      display: none;
    }
  }

  .animation {
    transition-duration: 1s;
    transform: translateY(-50%);
  }
}
