.destItem {
  position: relative;
  width: var(--grouping-width);
  height: var(--grouping-height);
  border-style: solid;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.label {
  position: absolute;
  display: flex;
  width: 100%;
  max-height: 100%;
  top: 50%;
  left: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-word;
  transform: translateY(-50%);
  overflow: scroll;
  z-index: 0;
}

.placeholder {
  font-size: 16px;
  color: var(--color-placeholder);
}

.mask {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.innerText {
  max-height: 100%;
}
