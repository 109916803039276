.root {
  display: flex;
  align-items: center;
}

.progress {
  position: relative;
  width: 86px;
  height: 3px;
  margin-right: 8px;
  margin-left: 1px;
  background-color: var(--color-delimiter-main);
}

.done {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-secondary-default);
}

.fail {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-secondary-default);
}
