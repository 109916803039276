.label {
  position: absolute;
  display: flex;
  width: 100%;
  max-height: 100%;
  top: 50%;
  left: 0;
  justify-content: center;
  text-align: center;
  word-break: break-word;
  transform: translateY(-50%);
  overflow: scroll;
  z-index: 0;
}

.placeholder {
  font-size: 16px;
  color: var(--color-placeholder);
}

.mask {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.iconWrapper {
  position: absolute;
  display: flex;
  bottom: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: var(--color-background-main);
  font-size: 24px;
}

.icon {
  width: 1em;
  height: 1em;
  margin: 0;
  background-size: contain;
}
