.test-info-form__item .ant-form-item {
  margin-bottom: 0;

  .ant-form-item-control-input-content > .ant-input.ant-input {
    height: 32px;
  }

  .ant-btn-icon-only.test-info-label__button {
    display: flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    align-items: center;
    justify-content: center;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.test-info-form__legend {
  display: block;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 13px;
}
