.root {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.wrapperNotification {
  display: flex;
  width: 100%;
  height: 0;
  align-items: center;
  transition: height 0.3s ease;
  z-index: 100;

  &.open {
    height: 52px;
  }
}

.wrapperChildren {
  flex-grow: 1;
  overflow: hidden;
}
