.root {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  white-space: pre-wrap;
  word-break: break-word;
}

.short {
  max-height: 96px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding-top: 16px;
}
