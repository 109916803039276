.root {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: var(--color-background-main);

  &:hover {
    background-image: none !important;
  }
}

.icon {
  position: absolute;
  display: flex;
  z-index: 10;

  &.drag {
    display: none;
  }
}

.dragIcon {
  display: none;

  &.drag {
    display: flex;
  }
}

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}
