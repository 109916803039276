.labels {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  gap: 2px;
  font-size: 11px;
  transform: translate(0, -100%);
}

.nodeLabel {
  padding: 2px 4px;
  background-color: var(--color-secondary-default);
  white-space: nowrap;
  overflow-wrap: break-word;
  color: var(--color-background-main);
}

.user {
  padding: 2px 4px;
  color: var(--color-background-main);
}
