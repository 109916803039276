.title {
  padding: 0 24px;
  font-size: 13px;
  line-height: 32px;
  color: var(--bodyFontColor);
}

.searchNum {
  margin-left: 8px;
  color: var(--color-text-secondary);
}

.resultsContent {
  margin: 32px 24px;
  border-bottom: 1px solid var(--color-background-secondary);
}

.resultItem {
  display: flex;
  border-top: 1px solid var(--color-background-secondary);
}
