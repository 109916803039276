.root {
  display: flex;
  width: 100%;
  gap: 4px;
}

.between {
  justify-content: space-between;
}

.center {
  justify-content: center;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}
