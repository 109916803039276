.root {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
  border-radius: 20px;
  background-color: var(--color-disabled);
}

.switch input {
  width: 0;
  height: 0;
  opacity: 0;
}

.slider {
  position: absolute;
  border-radius: 20px;
  background-color: var(--color-disabled);
  transition: 0.4s;
  cursor: pointer;
  inset: 0;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: 3px;
    left: 3px;
    border-radius: 50%;
    background-color: var(--color-background-main);
    transition: 0.4s;
  }

  &.active:before {
    background-color: var(--color-placeholder);
  }
}

input:checked + .slider {
  background-color: var(--color-primary-default);
}

input:checked + .slider:before {
  transform: translateX(14px);
}
