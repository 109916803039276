.root {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  overflow: hidden;

  &.gap {
    gap: 16px;
  }

  &.side {
    padding-right: 16px;
    padding-left: 16px;
  }

  &.top {
    padding-top: 16px;
  }

  &.bottom {
    padding-bottom: 16px;
  }

  &.bottomSpace {
    padding-bottom: 56px;
  }
}
