.file-portal {
  position: fixed;
  width: 320px;
  right: 24px;
  bottom: 40px;
  z-index: 100000;
}

.file-portal:empty {
  display: none;
}
