.root {
  --infiniteColumnWidth: 302px;
  --infiniteColumnGap: 24px;
  --infiniteRowGap: 32px;

  display: grid;
  max-height: 100%;
  grid-template-columns: repeat(auto-fill, minmax(var(--infiniteColumnWidth), 1fr));
  justify-content: space-between;
  grid-row-gap: var(--infiniteRowGap);
  grid-column-gap: var(--infiniteColumnGap);
  margin-bottom: 2px;
}

.loadingItem {
  display: flex;
  width: 100%;
  height: 100px;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
