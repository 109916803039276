.root {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in;

  &.inline {
    display: inline-block;
    width: auto;
    flex-grow: 0;
  }

  &.fixed,
  &.absolute {
    inset: 0;
  }

  &.fixed {
    position: fixed;
  }

  &.absolute {
    position: absolute;
  }
}

.spinner {
  display: flex;
  font-size: 24px;
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.resources {
  margin-top: 12px;
  color: var(--color-text-secondary);
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
