.root {
  padding: 8px;
}

.answers {
  position: relative;
  width: 100%;
  height: 220px;
}

.groups {
  display: flex;
  flex-direction: column;

  &.tableView {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
