.collapseIcon {
  display: flex;
  width: 24px;
  flex-shrink: 0;
  align-items: center;
  font-size: 24px;
}

.nodeIndent {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.description {
  grid-area: description;
}

.sectionStatus {
  height: 100%;
  grid-area: progress;
}

.line {
  height: 100%;
  grid-area: line;
}

.collapse {
  grid-area: collapse;
}

.item {
  display: flex;
  overflow: hidden;
}

.itemIcon {
  display: flex;
  align-items: center;
}

.itemContent {
  width: 100%;
  flex-grow: 1;
  word-break: break-all;
  overflow: hidden;
}

.iconImage {
  width: 20px;
  height: 20px;
  grid-area: icon;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.customIcon {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
