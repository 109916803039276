.info {
  display: flex;
  height: 210px;
  justify-content: center;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  position: relative;
  display: flex;
  font-size: 40px;
}

.name {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  font-size: 13px;
  cursor: pointer;
}

.textWrap {
  display: block;
  overflow: hidden;
}

.counter {
  padding: 1px 5px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: 20px;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: var(--color-text-main);
}

.brand {
  height: 21px;
  margin-top: 4px;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--color-text-secondary);
}
