.fileItem,
.folderItem {
  display: flex;
  font-size: 40px;
}

.fileItem {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: contain;
}
