/* stylelint-disable color-no-hex */
.root {
  position: relative;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  font-size: 13px;
  line-height: 18px;
  cursor: text;

  &.disabled {
    cursor: not-allowed;
  }

  div::selection,
  &.placeholder {
    min-width: 70px;
  }

  span::selection {
    background: rgba(63, 131, 191, 0.49) !important;
  }

  > * {
    width: 100%;
  }
}

.pseudoSelection {
  position: relative;
  padding-top: 0.19em;
  padding-bottom: 0.17em;
  background-color: rgba(130, 130, 131, 0.49);
  z-index: 1;
}

// lines (marks, leaves)
.textBold {
  font-weight: 700;
}

.textStrikethrough {
  text-decoration: line-through;
}

.textItalic {
  font-style: italic;
}

.textUnderline {
  text-decoration: underline;
}

.textStrikethrough.textUnderline {
  text-decoration: underline line-through;
}

// END of lines (marks, leaves)

// Blocks (elements)
.numberedList,
.bulletedList {
  margin-bottom: 0;
  padding-left: 25px;
}

.listItem:not(:first-child),
.numberedList + .bulletedList,
.bulletedList + .numberedList {
  margin-top: 0.5em;
}

.elementBlockquote {
  margin-bottom: 0;
  margin-left: 5px;
  padding-left: 10px;
  border-left: 2px solid var(--color-delimiter-secondary);
  color: var(--color-text-secondary);
}

.heading1,
.heading2,
.heading3 {
  padding: 0;
  line-height: 1.5;
  color: var(--color-text-main);
}

.heading3 {
  margin-top: calc(21px - 0.5em);
  margin-bottom: calc(10px - 0.5em);
  font-weight: 600;
  font-size: 13px;
}

.heading2 {
  margin-top: calc(40px - 0.5em);
  margin-bottom: calc(21px - 0.5em);
  font-weight: 600;
  font-size: 16px;
}

.heading1 {
  margin-top: calc(40px - 0.5em);
  margin-bottom: calc(30px - 0.5em);
  font-size: 24px;
}

.link,
.link:hover {
  position: relative;
  cursor: pointer;
  color: var(--color-text-main);

  span {
    text-decoration: underline;
  }
}

.wrLinkHover {
  position: absolute;
  display: none;
  bottom: 100%;
  left: 0;
  padding-bottom: 8px;
  font-weight: 500;
}

.link:hover .wrLinkHover {
  display: flex;
}

.linkHover {
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-radius: 6px;
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);
  font-weight: 500;
}

.linkTitle {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 13px;
  line-height: 18px;
  white-space: nowrap;
  color: var(--color-text-main);

  &:hover {
    color: var(--color-primary-default);
  }
}

.mention {
  margin: 0 1px; /*  and add space for caret if last element */
  padding: 1px 4px;
  border-radius: var(--border-radius-inner);
  background-color: var(--color-green-alpha);
  font-weight: 400;
  font-size: 13;
  line-height: 18px;
  cursor: pointer;
  color: var(--color-green);
}
// END of Blocks (elements)
