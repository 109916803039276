.visible {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -58px;
}

.demo {
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-bottom: 82px;
  }
}

.buttonWrapper {
  display: flex;
  gap: 24px;
  margin-top: 20px;
}

.item {
  display: flex;
}

.empty {
  margin-top: -58px;
  text-align: center;

  .text {
    margin-top: 16px;
    font-size: 12px;
  }
}
