.portalContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000;

  > div {
    pointer-events: auto;
  }
}
