.positionRight {
  right: 12px;
  left: auto;
  margin-right: 18px;
  margin-left: 24px;

  &.floatingButton {
    margin-right: 0;
  }
}

.logo {
  max-width: 100%;
  padding: 6px;
  border: none;
  border-radius: var(--border-radius-inner);

  &:hover {
    background-color: var(--color-background-secondary);
  }

  &.disabled {
    background-color: transparent;
    cursor: not-allowed;
  }

  img {
    height: 32px;
    max-width: 100%;
    max-height: 32px;
    object-fit: contain;
  }
}

.center {
  display: flex;
  min-width: 64px;
  flex-shrink: 1000;
  justify-content: center;
  overflow: hidden;
}

.title {
  max-width: 100%;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.headerRightFix {
  display: flex;
  flex: 1 1 160px;
  align-items: center;
  margin-left: 16px;
}
