.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-paddingTop) var(--padding-paddingLeft);
  border: none;
  background-color: var(--button-backgroundColor);
  background-image: var(--button-backgroundImage);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  white-space: nowrap;
  cursor: pointer;

  &:after {
    content: var(--button-backgroundImage) var(--disabled-backgroundImage);
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
  }

  .icon {
    width: 1em;
    height: 1em;
    margin: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.left {
    flex-direction: row-reverse;

    .icon {
      margin-right: 10px;
    }
  }

  &.right {
    flex-direction: row;

    .icon {
      margin-left: 10px;
    }
  }
}

.root.disabled {
  background-color: var(--disabled-backgroundColor);
  background-image: var(--disabled-backgroundImage);
  cursor: not-allowed;
}

.iconLeft {
  margin-right: 10px;
}

.iconRight {
  margin-left: 10px;
}
