.root {
  display: flex;
  align-items: center;
  cursor: pointer;

  .indeterminate {
    width: 6px;
    height: 2px;
    margin: 0 3px;
    border-radius: 1px;
    background-color: var(--color-placeholder);
  }

  .checkbox {
    position: relative;
    display: flex;
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border: 1px solid var(--color-placeholder);
    border-radius: 3px;

    &:last-child {
      margin-right: 0;
    }
  }

  .label {
    display: flex;
    font-size: 13px;
    line-height: 18px;
    color: inherit; // var(--color-text-main);
  }

  &:hover .checkbox,
  .input:focus + .checkbox {
    border: 1px solid var(--color-input-focus);

    .indeterminate {
      background-color: var(--color-input-focus);
    }
  }

  .input:checked + .checkbox {
    border: 1px solid var(--color-primary-default);
    background-color: var(--color-primary-default);
  }

  .input:disabled + .checkbox {
    border: 1px solid var(--color-placeholder);
    background-color: var(--color-background-secondary);
  }

  &.disabled {
    cursor: not-allowed;

    .label {
      color: var(--color-text-secondary);
    }
  }

  &.hideCheckbox .label {
    padding: 4px;
  }

  &.hideCheckbox.checked .label {
    border-radius: var(--border-radius-inner);
    color: var(--color-primary-default);
  }

  &.disabled.hideCheckbox.checked .label {
    color: var(--color-text-secondary);
  }

  &.medium {
    height: 32px;
  }
}

.input {
  position: absolute;
  opacity: 0;
  appearance: none;
}

.checkIcon {
  display: flex;
  width: 12px;
  height: 12px;
}
