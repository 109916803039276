.item {
  display: flex;
  width: 100%;
  align-items: center;
  white-space: pre-wrap;
}

.burgerIcon {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  color: var(--color-text-main);
}

.icon {
  width: 24px;
  height: 24px;
  margin: 0;
  background-size: contain;
}

.placeholder {
  font-size: 13px;
  color: var(--color-placeholder);
}

.text {
  display: inline;
  width: 100%;
  font-size: 16px;
  vertical-align: middle;
}
