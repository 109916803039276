.modalBody {
  display: flex;
  height: 432px;
  max-height: 100%;
  flex-direction: column;
}

.projectTable {
  display: flex;
  flex-grow: 1;
  margin-top: 20px;
}
