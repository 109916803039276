.centerOfPoint {
  position: absolute;
  display: flex;
  top: 50%;
  left: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateY(-50%);
}
