.root {
  display: flex;
  width: 100%;
  align-items: center;
}

.dot {
  width: 2px;
  height: 2px;
  margin: 4px;
  border-radius: 1px;
  background-color: var(--color-text-main);

  &.read {
    background-color: var(--color-text-secondary);
  }
}
