.root {
  position: relative;
  display: flex;
  cursor: pointer;
}

.header {
  display: flex;
  max-width: 100%;
  flex-grow: 1;
  flex-direction: column;
}

.inlineBlock {
  display: inline-block;
}

.fluid {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.disabled {
  cursor: not-allowed;
}
