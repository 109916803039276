.root {
  display: flex;
  width: 464px;
  align-items: center;
  gap: 8px;
  padding: 6px;
  border-radius: var(--border-radius-main);
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);
}

.input {
  flex-grow: 1;
}

.controls {
  display: flex;
  margin-left: 6px;
}
