.root {
  --layout-margin-bottom: 72px;

  display: flex;
  min-height: calc(100% - var(--layout-margin-bottom));
  flex-shrink: 1;
  flex-direction: column;
  justify-content: center;
  margin: 0 48px var(--layout-margin-bottom);
}

.free {
  margin: 0 0 var(--layout-margin-bottom);
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.medium {
  width: 1080px;
  margin: 0;
}

.mini {
  width: 640px;
  margin: 0 auto;
}
