.contentWrapper {
  position: relative;
  display: flex;
  border-radius: 6px;
}

.root {
  position: relative;
  margin-bottom: 4px;
}

.usersList {
  position: absolute;
  display: flex;
  min-width: 76px;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
}

.collapseIconWrapper {
  display: flex;
  margin-top: 10px;
  margin-left: 2px;
}

.collapseIcon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 4px;
  border-radius: 6px;
  box-shadow: var(--box-shadow-default);
  font-size: 20px;
  transition: transform 0.3s ease;
  transform: rotate(-90deg);

  &.isOpen {
    transform: rotate(0deg);
  }
}

.nodeIndent {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.nodeContent {
  position: relative;
  width: 100%;
  flex-grow: 1;
}

.cover {
  color: var(--color-text-secondary);
}

.chapter {
  margin-left: 2px;
  color: var(--color-text-secondary);
}
