.root {
  position: fixed;
  pointer-events: none;
  z-index: 10000;
  inset: 0;
}

.list {
  position: absolute;
  display: flex;
  width: 340px;
  right: 24px;
  bottom: 60px;
  flex-direction: column-reverse;
  gap: 16px;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 6px;
  box-shadow:
    0 2px 10px 0 rgba(0, 0, 0, 0.1),
    0 0 2px 0 rgba(0, 0, 0, 0.2);
  background: var(--color-background-main);
  font-size: 12px;
  pointer-events: all;

  &.error {
    background-color: var(--color-error);
  }
}

.closeIconWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-text-main);
}

.block {
  display: flex;
  flex-grow: 1;
}

.content {
  display: flex;
  width: 100%;
}

.divider {
  width: 1px;
  margin: 0 20px 0 12px;
  background-color: var(--color-delimiter-main);

  &.opacity {
    opacity: 0.5;
  }
}

.icon {
  margin-right: 12px;

  &.success {
    color: var(--color-success);
  }

  &.info {
    color: var(--color-g);
  }
}

.closeIcon {
  &.error {
    color: var(--color-background-main);
  }
}

.message {
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: var(--color-text-main);

  &.error {
    color: var(--color-background-main);
  }
}
