.root {
  position: relative;
  display: inline-block;
}

.readonly {
  pointer-events: none;
}

.demo {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-delimiter-main);
  border-radius: 16px;
}

.tooltip {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.slider {
  flex-grow: 1;
}

.close {
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 0;
}

.navigation {
  padding: 20px 0;
}

@media print {
  .root {
    display: block;
    break-inside: avoid;
  }

  .pdfItem {
    display: block;
    break-inside: avoid;
  }
}
