.root {
  display: flex;
  width: 260px;
  flex-direction: column;
  gap: 10px;
  margin: 4px;
}

.rule {
  display: flex;
  gap: 8px;
  color: var(--color-text-secondary);
}
