.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 16px;
  padding: 4px 0;

  &:not(:first-child) {
    .line {
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 16px;
        top: -6px;
        left: 11.5px;
        border-right: 1px dashed var(--color-delimiter-secondary);
      }
    }
  }
}

.title {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
