.root {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-main);

  &.horizontal {
    align-items: center;
    border-radius: var(--border-radius-main);
    box-shadow: var(--box-shadow-default);
  }
}

.title {
  padding: 12px;
}

.footer {
  padding: 12px;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;

  &.horizontal {
    height: 40px;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

.empty {
  padding: 9px 12px;

  &.horizontal {
    align-items: center;
    border-radius: var(--border-radius-main);
    box-shadow: var(--box-shadow-default);
    background-color: var(--color-background-main);
  }
}
