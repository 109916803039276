.mode {
  min-width: 57px;
  padding: 0 6px;
  border-radius: 6px;
  background-color: var(--color-delimiter-main);
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--color-text-secondary);
}
