.warning {
  display: flex;
  flex-shrink: 0;
  margin-right: 13px;
  font-size: 24px;
  color: var(--color-background-main);
}

.textWrapper {
  width: 100%;
}

.textInfo {
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.linkUrlBlock {
  display: flex;
  max-width: 50%;
  flex-grow: 0;
  align-items: center;
  margin-left: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  .linkUrl {
    padding-right: 15px;
    color: inherit;
    text-decoration-line: underline;
  }
}

.dateBlock {
  display: flex;
  flex-shrink: 0;
  margin-left: 4px;
}

.messageBlock {
  display: flex;
  max-width: 50%;
  flex-grow: 0;
  overflow: hidden;
}
