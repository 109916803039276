.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: var(--border-radius-main);
  background-color: transparent;
  transition: transform var(--transition-duration);
  cursor: pointer;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:after {
    content: '';
    position: absolute;
    border: 1px solid transparent;
    border-radius: inherit;
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition: inherit;
    pointer-events: none;
    z-index: 1;
    inset: -1px;
  }
}

.group {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-main);
  background-color: transparent;
  transition: all 0.2s ease-in-out;

  .button {
    &:first-child {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(&:first-child, &:last-child) {
      border-right: none;
      border-left: none;
      border-radius: 0;
    }

    &.large {
      padding: 0 12px;
    }

    &.medium {
      padding: 0 10px;
    }

    &.mini {
      padding: 0 8px;
    }

    &.xmini {
      padding: 0 6px;
    }
  }
}

.disabled {
  cursor: not-allowed;
}

.fluid {
  width: 100%;
  flex-shrink: 0;
}

.loading .content {
  opacity: 0;
}

.icon-large {
  width: 40px;
}

.large {
  height: 40px;
  padding: 0 20px;

  &.round {
    border-radius: 20px;
  }

  .content {
    gap: 8px;
    font-size: 13px;
  }
}

.icon-medium {
  width: 32px;
}

.medium {
  height: 32px;
  padding: 0 12px;

  &.round {
    border-radius: 16px;
  }

  .content {
    gap: 4px;
    font-size: 13px;
  }
}

.icon-mini {
  width: 28px;
}

.mini {
  height: 28px;
  padding: 0 10px;

  &.round {
    border-radius: 14px;
  }

  .content {
    gap: 4px;
    font-size: 13px;
  }
}

.xmini {
  height: 20px;
  padding: 0 8px;

  &.round {
    border-radius: 10px;
  }

  .content {
    gap: 2px;
    font-size: 12px;
  }
}

.icon-xmini {
  width: 20px;
}

.primary {
  background-color: var(--color-primary-default);
  color: var(--color-white);

  &:focus-visible:after {
    outline: 2px solid var(--color-input-focus);
  }

  &:hover {
    background-color: var(--color-primary-active);
  }

  &:active {
    background-color: var(--color-input-focus);
  }

  &.active {
    background-color: var(--color-input-focus);
  }

  &.loading {
    background-color: var(--color-input-focus);
  }

  &.inheritColor {
    background-color: transparent;
    color: currentColor;
  }

  &.disabled {
    outline: 2px solid transparent;
    background-color: var(--color-disabled);
    color: var(--color-text-secondary);
  }
}

.secondary {
  border: 1px solid var(--color-delimiter-main);
  background-color: var(--color-background-main);
  color: var(--color-text-main);

  &:focus-visible:after {
    outline: 2px solid var(--color-input-focus);
  }

  &:hover {
    color: var(--color-primary-default);
  }

  &:active {
    color: var(--color-input-focus);
  }

  &.active {
    background-color: var(--color-background-secondary);
  }

  &.loading {
    background-color: var(--color-background-main);
  }

  &.inheritColor {
    border-color: currentColor;
    background-color: transparent;
    color: currentColor;
  }

  &.disabled {
    outline: 2px solid transparent;
    background-color: var(--color-background-secondary);
    color: var(--color-text-secondary);
  }
}

.ghost {
  background-color: transparent;
  color: var(--color-text-main);

  &:focus-visible:after {
    outline: 2px solid var(--color-input-focus);
  }

  &:hover {
    color: var(--color-primary-default);
  }

  &:active {
    color: var(--color-input-focus);
  }

  &.active {
    background-color: var(--color-background-secondary);
  }

  &.inheritColor {
    color: currentColor;
  }

  &.disabled {
    outline: 2px solid transparent;
    color: var(--color-text-secondary);
  }
}

.accent {
  background-color: var(--color-secondary-default);
  color: var(--color-white);

  &:focus-visible:after {
    outline: 2px solid var(--color-secondary-default);
  }

  &:hover {
    background-color: var(--color-secondary-default);
  }

  &:active {
    background-color: var(--color-secondary-default);
  }

  &.active {
    background-color: var(--color-secondary-default);
  }

  &.disabled {
    outline: 2px solid transparent;
    background-color: var(--color-disabled);
  }
}

.free {
  &:focus-visible:after {
    outline: 2px solid var(--color-primary-default);
  }

  &.disabled {
    outline: 2px solid transparent;
    color: var(--color-text-secondary);
  }
}
