.root {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  overflow: hidden;
}

.medium {
  max-width: 1080px;
  margin: 0;
}

.mini {
  max-width: 640px;
  margin: 0 auto;
}

.large {
  margin: 0 auto;
}
