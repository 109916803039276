.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px;
  padding-left: 24px;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    top: 13px;
    left: 4px;
    border-left: 1px dashed var(--color-delimiter-main);
  }

  &:last-child {
    &:before {
      height: calc(100% - 38px);
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    top: 4px;
    left: 0;
    border: 1px solid var(--color-delimiter-main);
    border-radius: 5px;
    background-color: var(--color-background-main);
  }
}

.content {
  flex-grow: 1;
}

.time {
  font-weight: 500;
  font-size: 12px;
  color: var(--color-text-main);
}

.title {
  font-weight: 500;
  font-size: 12px;
  color: var(--color-text-secondary);
}
