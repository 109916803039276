.root {
  --color-input-placeholder: var(--color-text-secondary);

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 0;
  border-radius: 6px;
  background-color: var(--color-background-main);

  input {
    &::placeholder {
      color: var(--color-input-placeholder);
    }
  }

  // TODO: fix after the course component appears
  & > div {
    height: auto !important;
    margin-right: 3px;
  }
}

.prefixWrapper {
  display: flex;
  padding-right: 8px;
}

.fluid {
  width: 100%;
}
