.root {
  display: flex;
  width: 300px;
  flex-direction: column;
  margin: 25px;
}

.title {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  align-items: center;
}

.pre {
  margin: 0;
}
