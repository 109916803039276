.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  user-select: none;
}

.absolute {
  position: absolute;
  inset: 0;
}

.grow {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.xmini {
  font-size: 40px;
}

.mini {
  font-size: 80px;
}

.medium {
  font-size: 120px;
}

.large {
  font-size: 200px;
}
