.items {
  position: relative;
  display: flex;
  flex-direction: column;
}

.text {
  width: 100%;
}

.wrapItem {
  position: relative;
  width: 100%;

  /* margin-bottom: var(--offset-marginTop);
  border-radius: var(--brand-borderRadius);
  overflow: hidden; */

  &:hover .dnd {
    display: flex;
  }

  &.active {
    background-color: var(--brand-primaryBackgroundColor);
  }
}

.hover:hover {
  background-color: var(--color-primary-hover);
}
