.root {
  display: flex;
  flex-direction: column;
}

.timeLine {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
