.root {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 20px;
}

.collections {
  width: 100%;
  padding-bottom: 24px;

  &.grow {
    height: 100%;
  }

  &.empty {
    padding: 0;
  }
}

.groupSearch {
  padding-bottom: 24px;
}

.tags {
  width: 220px;
  // height: 100%;
}
