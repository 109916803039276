.dnd {
  position: absolute;
  display: none;
  width: 16px;
  height: 24px;
  top: 50%;
  right: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);
  transform: translateY(-50%);
}

.wrapItem {
  position: relative;

  &:hover .dnd.show {
    display: flex;
  }

  &.active {
    background-color: var(--brand-primaryBackgroundColor);
  }
}
