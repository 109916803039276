.root {
  display: flex;
  align-items: center;
}

.iconButton {
  display: flex;
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: 6px;
  background-color: var(--color-background-main);
}
