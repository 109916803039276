.root {
  position: relative;
  padding: 10px;
  overflow: hidden;

  .card {
    padding: 15px;
    border: 2px solid transparent;
    border-radius: 6px;
    box-shadow: var(--box-shadow-default);
    cursor: pointer;

    &.active,
    &:hover {
      border: 2px solid var(--color-input-focus);
      box-shadow: none;
    }
  }

  .actions {
    display: none;
  }

  &.withActions {
    &:hover {
      .actions {
        position: absolute;
        display: block;
        top: 8px;
        right: 8px;
      }
    }
  }

  .favIcon:not(.favIconSelected) {
    opacity: 0;
  }

  &:hover .favIcon {
    opacity: 1;
  }
}

.bottomGroup {
  display: flex;
  min-width: 90%;
  align-items: center;
  justify-content: space-between;
}

.blockWrapper {
  position: relative;
  border-radius: 2px;
}

.header {
  display: flex;
}

.blockTitle {
  margin-right: 20px;
  margin-left: 4px;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &:hover .multipleChoiceIcon {
    color: var(--color-primary-default);
  }
}
