.root {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  font-weight: 400;
  overflow: hidden;
  z-index: 1;
  will-change: backface-visibility;
  backface-visibility: hidden;
  transform-style: preserve-3d;

  // book animation -----------------------
  &.diagonalFlip {
    &.back {
      transform: rotate3d(-1, 1, 0, 180deg);
    }
  }

  &.horizontalFlip {
    &.back {
      transform: rotateY(180deg);
    }
  }

  &.verticalFlip {
    &.back {
      transform: rotateX(180deg);
    }
  }

  &.fadeEffect {
    transition: opacity 1s ease;
    opacity: 0;
    z-index: 0;

    &.active {
      opacity: 1;
      z-index: 1;
    }
  }

  &.book {
    width: 200%;
    transform: rotateY(0deg);
  }
  // ------------------------------
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.textContainer {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  padding-right: 16px;
  padding-left: 16px;
  word-break: break-word;
  overflow: scroll;
  z-index: 2;
}

.textInner {
  width: 100%;
  max-height: 100%;
  white-space: pre-wrap;
}

.icon {
  position: absolute;
  display: flex;
  width: 40px;
  height: 40px;
  top: 16px;
  left: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 24px;
  cursor: pointer;
  backface-visibility: hidden;
}
