.root {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin-bottom: 13px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--color-text-main);
}

.description {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: var(--color-text-secondary);
}

.platformLogo {
  width: 142px;
  height: 38px;
}

.goToPlatform {
  display: flex;
  align-items: center;
  margin-top: 32px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

.linkText {
  margin-left: 8px;
  border-bottom: 1px solid var(--color-primary-default);
  font-size: 13px;
  line-height: 14px;
}

.link {
  cursor: pointer;
}
