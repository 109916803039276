.root {
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 16px;
}
