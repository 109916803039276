.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 32px 64px;
}

.header {
  display: flex;
  margin: 20px 0;
}

.content {
  display: flex;
  max-height: 100%;
  flex-grow: 1;
  flex-direction: column;
}

.breadcrumbs {
  margin: 0 32px;
}

.alert {
  display: flex;
  margin: 20px 32px 0;
}
