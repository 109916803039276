.root {
  display: flex;
  width: 80vw;
  height: 80vh;
  flex-direction: column;
  margin: auto;
  padding: 20px;
  /* stylelint-disable-next-line color-named */
  background-color: white;
  overflow: scroll;
}

.header {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 25px;
  margin-bottom: 16px;
}

.customField {
  display: flex;
  align-items: center;
  margin: 0 25px;
}
