.root {
  --section-offset-y: 10px;

  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 6px;
  background: var(--color-background-main);
  overflow: hidden;

  .actions {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 6px;
    margin-right: 6px;
    opacity: 0;
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }

  &:not(.activeItem, .chapter):hover {
    background-color: var(--color-primary-hover);

    .wrapActions {
      background-color: var(--color-primary-hover);
    }
  }

  &.activeItem {
    padding: 0;
    border: 1px solid var(--color-input-focus);
    border-radius: 6px;

    .actions {
      opacity: 1;
    }
  }
}

.item {
  display: flex;
  flex-grow: 1;
  gap: 10px;
  border-radius: 6px;
  font-weight: 400;
  cursor: pointer;

  .selected {
    opacity: 1;
  }

  .itemIcon {
    display: flex;
    margin: var(--section-offset-y) 0 var(--section-offset-y) 12px;
    font-size: 20px;
    color: var(--color-text-main);
  }

  .itemIconCover,
  .itemIconChapter {
    color: var(--color-text-secondary);
  }

  .icon {
    display: flex;
    margin-top: 10px;
    margin-right: 4px;
    font-size: 20px;
    color: var(--color-input-focus);
  }

  .active {
    opacity: 1;
  }
}

.itemContent {
  display: flex;
  // width: calc(100% - 60px);
  left: 32px;
  flex-grow: 1;
  font-size: 13px;
  overflow: hidden;
  z-index: 2;
}

.save {
  display: flex;
  margin-top: 6px;
  margin-right: 6px;
}

.reset {
  padding: 0;
}

.itemTitle {
  display: flex;
  flex-grow: 1;
  margin-right: 4px;
}

.control {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 2px 0 2px 8px;
}

.dragControl {
  display: flex;
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 1;
}

.disabled {
  cursor: not-allowed;
}
