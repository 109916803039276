.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}

.title {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.active {
  background-color: var(--opacity-hover-preview);
}
