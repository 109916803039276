.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
}

.columnControl {
  display: flex;
  height: 36px;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: 6px;
  background: var(--color-background-main);
}

.title {
  flex: 1;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
}
