.root {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text-secondary);
}

.label {
  font-size: 13px;
  color: var(--color-text-main);
}

.inputHex {
  width: 100px;
  margin-left: 13px;
  font-size: 13px;
}

.inputAlpha {
  width: 68px;
  margin-left: 8px;
  font-size: 13px;
}
