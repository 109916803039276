[contenteditable] {
  border: none;
  outline: none;
  -webkit-user-select: text;
  user-select: text;
}

input {
  outline: none;
}

::selection {
  background: rgb(220, 220, 220);
  color: inherit !important;
}

&:-webkit-autofill:first-line,
&:-webkit-autofill,
&:-webkit-autofill:hover,
&:-webkit-autofill:focus,
&:-webkit-autofill:active {
  font-size: 14px;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

.ant-input-affix-wrapper > input.ant-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--color-background-secondary) inset;
  background-color: transparent !important;
}

.ant-input-affix-wrapper > input.ant-input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 50px var(--color-background-secondary) inset !important;
}

.ant-input-affix-wrapper > input.ant-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px var(--color-background-main) inset !important;
  background-color: transparent !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--color-placeholder);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

html,
body {
  height: 100%;
  overflow: hidden;
  -webkit-print-color-adjust: exact;
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

mark {
  padding: 0 !important;
}

#root {
  height: 100%;
  font-family: Inter, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant-ligatures: none;
}
