.root {
  display: flex;
  cursor: pointer;
}

.previewImg {
  height: 20px;
  min-width: 20px;
  margin-right: 16px;
  border-radius: var(--border-radius-inner);
  background-size: cover;
}

.icon {
  margin-right: 16px;
  color: var(--color-text-main);
}

.content {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.title {
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
