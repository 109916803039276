.root {
  position: relative;
  display: flex;
  width: 100%;
  height: 52px;
  flex: 1;
  align-items: center;
  padding: 12px 52px;
  overflow: hidden;
}

@media screen and (width <= 800px) {
  .root {
    padding: 12px 16px;
  }
}

.content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 18px;
  overflow: hidden;
}

.info {
  background: var(--color-blue);
  color: var(--color-white);
}

.warning {
  background: var(--color-yellow);
  color: var(--color-black);
}

.error {
  background: var(--color-red);
  color: var(--color-white);
}

.success {
  background: var(--color-green);
  color: var(--color-white);
}

.close {
  position: absolute;
  display: flex;
  right: 16px;
}
