.test-info-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.test-info-item__icon {
  display: flex;
  min-width: 40px;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: 50%;
  background-color: var(--color-background-main);
  font-size: 20px;
}

.test-info-item__img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-right: 16px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: 50%;
}

.test-info-item--isVisible--false {
  display: none;
}
