.root {
  position: absolute;
  display: flex;
  flex-direction: column;
  inset: 0;
}

.fillMode {
  pointer-events: none;
}
