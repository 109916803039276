.root {
  position: fixed;
  pointer-events: none;
  overflow: hidden;
  z-index: 1000;
  inset: 0;

  .mask {
    position: absolute;
    display: none;
    background: transparent;
    pointer-events: auto;
    opacity: 0;
    z-index: 1000;
    inset: 0;

    &.open {
      display: block;
      animation: maskOpacity 0.3s ease-out;
      opacity: 0.45;
    }

    &.hidden {
      display: none;
      animation: maskOpacityHide 0.3s ease-out;
      opacity: 0;
    }

    &.maskBg {
      background: var(--color-black);
    }
  }

  .contentWrapper {
    position: absolute;
    max-width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    box-shadow: var(--box-shadow-default);
    transition: transform 0.3s;
    overflow: hidden;
    z-index: 1000;

    &.hidden {
      box-shadow: none;
    }

    .header {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      border-bottom: 1px solid var(--color-delimiter-secondary);
      border-radius: 2px 2px 0 0;
      background: var(--color-background-main);
      color: var(--color-text-main);

      .title {
        flex-grow: 1;
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: var(--color-text-main);
      }

      .close {
        display: flex;
        margin-left: 30px;
      }
    }

    .content {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      background-color: var(--color-background-main);
      pointer-events: auto;
    }

    &.left.hidden {
      transform: translateX(-100%);
    }

    &.top.hidden {
      transform: translateY(-100%);
    }

    &.right {
      right: 0;
      left: auto;

      &.hidden {
        transform: translateX(100%);
      }

      &.open {
        transform: translateX(0);
      }
    }

    &.bottom {
      top: auto;
      bottom: 0;

      &.hidden {
        transform: translateY(100%);
      }

      &.open {
        transform: translateY(0);
      }
    }
  }
}

@keyframes maskOpacity {
  0% {
    display: none;
    opacity: 0;
  }

  50% {
    display: block;
    opacity: 0.2;
  }

  100% {
    display: block;
    opacity: 0.45;
  }
}

@keyframes maskOpacityHide {
  0% {
    display: block;
    opacity: 0.45;
  }

  50% {
    display: block;
    opacity: 0.2;
  }

  100% {
    display: none;
    opacity: 0;
  }
}
