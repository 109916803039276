.mask {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #1d2b4b;
  transition: 0.5s;
  opacity: 0.8;
}

.icon {
  width: 1em;
  height: 1em;
  background-size: contain;
  font-size: 24px;
}
