.root {
  display: flex;
  width: 464px;
  flex: 1;
  flex-direction: column;
  padding: 6px;
  border-radius: var(--border-radius-main);
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);

  &.responseContainer {
    height: 460px;
  }
}

.loader {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.input {
  flex-grow: 1;
}

.controls {
  display: flex;
  align-items: flex-end;
  margin-left: 6px;
}

.commandsList {
  display: flex;
  flex-direction: column;
}

.prompt {
  display: flex;
  flex: 1;
}

.resultTitle {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid var(--color-delimiter-main);
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

.result {
  position: relative;
  display: flex;
  max-height: 278px;
  flex: 1;
  flex-direction: column;
  font-size: 13px;
  white-space: pre-line;
}

.resultText {
  padding: 0 16px;
}

.bottomControls {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  border-top: 1px solid var(--color-delimiter-main);
}

.buttonsRight {
  display: flex;
  gap: 8px;
}

.iconWrapper {
  display: flex;
  height: 28px;
  align-items: center;
  margin: 0 4px 0 8px;
}

.promtTextCard {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  gap: 20px;
  margin: 12px 0;
  padding: 8px 12px;
  border-radius: var(--border-radius-main);
  box-shadow: var(--box-shadow-default);

  & > :last-child {
    align-self: flex-end;
  }
}

.title {
  display: flex;
  align-items: center;
  margin: 25px 16px;
}

.errorAlert {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
