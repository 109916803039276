.root {
  position: absolute;
  display: flex;
  top: 50%;
  left: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateY(-50%);
  overflow: visible;

  .image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.mask {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
