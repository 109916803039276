.root {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  display: flex;
  max-width: 134px;
  align-items: center;
  padding: 1px 6px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: var(--border-radius-inner);
  background-color: var(--color-background-secondary);
}

.inherit {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 14px;
}
