.root {
  position: relative;
  display: flex;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 16px;

    &.medium {
      margin-bottom: 24px;
    }

    &.large {
      margin-bottom: 28px;
    }
  }

  .label {
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 18px;
    -webkit-user-select: none;
    user-select: none;
    color: var(--color-text-secondary);
  }

  .input {
    display: flex;
    width: 100%;
    gap: 8px;
  }

  .info {
    margin-bottom: 8px;
  }
}

.horizontal {
  align-items: center;
  justify-content: space-between;

  .label {
    display: flex;
    min-width: 90px;
    align-items: center;
    margin-right: 8px;

    .icon {
      margin-left: 2px;
    }
  }

  .input {
    justify-content: flex-end;
  }
}

.vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  .label {
    margin-bottom: 4px;
  }

  .info {
    min-width: 100%;
  }
}

.solid {
  width: auto;
  max-width: 100%;
  flex-shrink: 0;

  .input {
    width: auto;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.root.table {
  margin-bottom: 0;
  border-bottom: 1px solid var(--color-delimiter-main);

  &:last-child {
    border-bottom: none;
  }

  &.medium {
    margin-bottom: 0;
  }

  .label {
    display: flex;
    width: 220px;
    align-items: flex-start;
    justify-content: space-between;
    padding: 25px 16px;
    border-right: 1px solid var(--color-delimiter-main);
    color: var(--color-text-secondary);
  }

  .input {
    padding: 22px 16px;
  }
}

.root.free {
  margin: 0;

  &.medium {
    margin: 0;
  }
}

.root.wrap .input {
  flex-wrap: wrap;
}

.root.overflow {
  .input {
    overflow: hidden;
  }
}

.errors {
  position: absolute;
  top: 100%;
  font-size: 11px;
  color: var(--color-input-error);
}

.required:after {
  content: '*';
  display: inline-block;
  margin: 0 4px 4px 2px;
  font-size: 10px;
  line-height: 1;
  color: var(--color-input-error);
  vertical-align: super;
}

.user {
  position: absolute;
  /* stylelint-disable-next-line color-no-hex */
  border: 1px solid #eee;
  pointer-events: none;
  z-index: 1;
  inset: -4px;
}

.userName {
  position: absolute;
  top: 0;
  left: -1px;
  padding: 2px 4px;
  font-size: 11px;
  transform: translate(0, -100%);
  color: var(--color-background-main);
}
