.root {
  margin-bottom: 16px;

  &.bordered {
    margin-bottom: 16px;
  }
}

.addButton {
  &.padding {
    padding: 0 16px;
  }
}
