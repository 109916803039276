.root {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.vertical {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.horizontal {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  .radio {
    position: relative;
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    border: 1px solid var(--color-placeholder);
    border-radius: 50%;

    &:last-child {
      margin-right: 0;
    }
  }

  .label {
    display: flex;
    font-size: 13px;
    line-height: 18px;
  }

  &:hover .radio,
  .input:focus + .radio {
    border: 1px solid var(--color-input-focus);
  }

  .input:checked + .radio {
    border: 4px solid var(--color-primary-default);
  }

  .input:disabled + .radio {
    border: 1px solid var(--color-placeholder);
    background-color: var(--color-background-secondary);
  }

  &.disabled {
    cursor: not-allowed;

    .label {
      color: var(--color-text-secondary);
    }
  }

  &.hideRadio .label {
    padding: 4px;
  }

  &.hideRadio.checked .label {
    border-radius: var(--border-radius-inner);
    color: var(--color-primary-default);
  }
}

.input {
  position: absolute;
  opacity: 0;
  appearance: none;
}
