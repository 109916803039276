.root {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info {
  max-width: 520px;
  margin: 0 16px 24px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: var(--color-text-secondary);
}

.buttonWrapper {
  span {
    font-size: 16px;
  }
}
