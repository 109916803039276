.root {
  max-height: 250px;
  overflow: auto;
}

.item {
  height: 32px;
  padding: 0 12px;
  line-height: 32px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-secondary);
  }
}

.active {
  color: var(--brand-primaryColor);
}
