:global {
  .os-host-flexbox {
    --os-scroll-vertical-right: 0;
    --os-scroll-vertical-left: unset;
    --os-scroll-horizontal-bottom: 0;
    --os-scroll-horizontal-top: unset;

    flex-grow: 1;
  }

  .os-host {
    width: 100%;

    .os-scrollbar.os-scrollbar-vertical {
      width: 6px;
      right: var(--os-scroll-vertical-right);
      left: var(--os-scroll-vertical-left);
      padding: 2px 2px 2px 0;

      .os-scrollbar-track {
        width: 4px;

        .os-scrollbar-handle {
          background-color: var(--color-placeholder);
        }
      }
    }

    .os-scrollbar.os-scrollbar-horizontal {
      height: 6px;
      top: var(--os-scroll-horizontal-top);
      bottom: var(--os-scroll-horizontal-bottom);
      padding: 0 2px 2px;

      .os-scrollbar-track {
        height: 4px;

        .os-scrollbar-handle {
          background-color: var(--color-placeholder);
        }
      }
    }
  }
}
