.root {
  display: flex;
  align-items: center;
  color: var(--color-text-secondary);
}

.right {
  justify-content: flex-end;
}

.active {
  color: var(--color-text-main);
}

.icon {
  margin-left: 5px;
  font-size: 12px;
}

.asc {
  transform: rotateX(180deg);
}

.sortable {
  cursor: pointer;
}
