.menuList {
  display: inline-flex;
  width: 200px;
  gap: 8px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 8px;
}

.alert {
  display: flex;
  flex-grow: 1;
  margin-bottom: 20px;
}
