.root {
  position: absolute;
  border: 2px solid #37d71d;
  background-color: #e6e6e633;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    &:hover {
      cursor: grab;
    }
  }

  &.selected.dragging,
  &.dragging {
    cursor: grabbing;
  }
}

.deleteIcon {
  position: absolute;
  bottom: -26px;
  left: 11px;
  transform: translate(-50%, 50%);

  &.disabled {
    cursor: not-allowed;
  }
}
