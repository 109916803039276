.root {
  cursor: pointer;
}

.item {
  display: flex;
  align-items: center;
}

.row {
  display: flex;
}

.fact {
  width: 90%;
  transition: 0.2s;

  &.active {
    width: 100%;
  }
}

.topLabel {
  padding: 16px;
  padding-left: 12px;
  border: solid;
  border-bottom: none;
}

.bottomLabel {
  padding: 16px;
  padding-left: 12px;
  border: solid;

  &.empty {
    border: dashed;
  }
}

.icon {
  width: 32px;
  height: 32px;
  margin-left: 12px;
  transition: 0.2s;
}

.customIcon {
  width: 100%;
  height: 100%;
  background-size: contain;
}

.arrowIcon {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-delimiter-main);
  border-radius: 50%;
  transform: rotate(-90deg);
}

.additions {
  width: 100vw;
  height: 100vh;
  max-width: 410px;
  max-height: none;
}
