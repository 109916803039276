// reset for brand base font lerna/packages/editor/src/brand/font.ts
.resetBaseFont {
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  font-family: Inter, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.571;
  color: var(--color-text-main);
}
