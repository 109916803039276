.root {
  display: grid;
  grid-template-areas:
    '. . tooltip . . . . . .'
    'play start seek finish points volume cc rate fullscreen';
  grid-template-columns: 28px 60px minmax(50px, auto) 60px max-content max-content max-content max-content max-content;
  align-items: center;
  column-gap: 8px;
  padding: 0 16px 16px;
  user-select: none;
}

.tooltip {
  position: relative;
  height: 16px;
  top: 12px;
  grid-area: tooltip;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--color-text-secondary);
}

.play {
  display: flex;
  grid-area: play;
  cursor: pointer;
  color: var(--color-primary-default);

  &.disabled {
    cursor: not-allowed;
    color: var(--color-placeholder);
  }
}

.buttons {
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.start {
  grid-area: start;
  line-height: 16px;
  text-align: center;
}

.seek {
  grid-area: seek;
}

.cc {
  grid-area: cc;
  font-size: 20px;
}

.finish {
  grid-area: finish;
  line-height: 16px;
  text-align: center;
}

.points {
  width: 60px;
  grid-area: points;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

.volume {
  grid-area: volume;
}

.rate {
  grid-area: rate;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.fullscreen {
  grid-area: fullscreen;
  font-size: 20px;
  cursor: pointer;
}

.audioPreview {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
}
