.root {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;

  .title {
    font-weight: 500;
    font-size: 13px;
    transition: 0.4s;
    cursor: pointer;
  }

  .defaultTitle {
    display: flex;
    align-items: center;

    .defaultTitleContent {
      flex-grow: 1;
    }
  }

  &.border {
    border-top: 1px solid var(--color-delimiter-main);
  }

  &.ghost {
    border: none;
  }
}

.content {
  transition: max-height 0.4s ease;
  overflow: hidden;
}

.contentWrapper {
  padding: inherit;
}
