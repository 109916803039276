.root {
  display: flex;
  width: 100%;
  align-items: center;

  .status {
    display: flex;
    width: 0;
    height: 100%;
    flex-shrink: 0;
    font-size: var(--common-width);
    transition: width 0.2s;
    overflow: hidden;

    &.visible {
      width: calc(var(--common-width) + var(--common-marginRight));
    }
  }

  .image {
    width: var(--common-width);
    height: var(--common-width);
    margin-right: var(--common-marginRight);
    background-size: contain;
  }
}

.content {
  max-width: 100%;
  flex-grow: 1;
}
