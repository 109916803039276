.root {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  border-radius: 6px;
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);

  &:empty {
    display: none;
  }
}
