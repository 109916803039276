:global {
  .ant-popover-placement-bottomRight {
    padding-top: 4px;
  }
}

.modalContainerBtnsBlock {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 16px;
}

.modalContainerBtnsBlockApplyBtn {
  margin-left: 8px;
  line-height: 18px;
}

.wrapperIconDropDown {
  margin-left: 2px;
  font-size: 13px;
  color: var(--color-text-main);
}

.settingPaginationTitle {
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: var(--border-radius-inner);
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  transition: 2s;
  cursor: pointer;
  user-select: none;
  color: var(--color-text-main);
}

.settingPaginationTitle:hover,
.settingPaginationTitle:global(.ant-popover-open) {
  background: var(--color-background-main);
}

.settingPaginationTitle:active {
  background: var(--color-background-secondary);
}

.settingPaginationTitlePages {
  margin: 0 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  &.isVisible {
    color: var(--color-primary-default);
  }
}
