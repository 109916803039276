.root {
  position: relative;
  display: inline-block;
  cursor: crosshair;

  &.preview {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;
  }
}

.image {
  display: block;
  width: 100%;
}

.hidden {
  transition: all 0.2s;
  opacity: 0;
}

.disabled {
  pointer-events: none;
}

.hint {
  font-size: 11px;
  color: var(--color-text-secondary);
}
