.matchingElement {
  width: 100%;
  padding: 8px;
}

.row {
  display: flex;
  width: 100%;
}

.item {
  height: 100%;
  flex: 1 1 50%;
}

.text {
  display: inline;
  width: 100%;
  font-size: 16px;
  vertical-align: middle;
}
