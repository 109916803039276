.matchingElement {
  display: grid;
  width: 100%;
  grid-auto-rows: 1fr;
  padding: 8px;
}

.row {
  position: relative;
  display: flex;
  width: 100%;

  &.active {
    background-color: var(--brand-primaryBackgroundColor);
  }
}

.item {
  flex: 1 1 50%;

  &:first-child {
    margin-right: -1px;
  }
}

.text {
  display: inline;
  width: 100%;
  font-size: 16px;
  vertical-align: middle;
}
