.root {
  display: flex;
  width: 100%;
  gap: 24px;
}

.blockWrapper {
  flex-grow: 1;
}

.block {
  position: relative;
  display: flex;
  height: 328px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border: 1px solid var(--color-neutral-200);
  border-radius: 6px;
}

.image {
  img {
    width: 64px;
    height: 64px;
  }
}
