.root {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &.desktop {
    padding: 24px 56px;
  }

  &.tablet {
    padding: 56px 24px 24px;

    .closeFix {
      max-height: 32px;
      flex: 9999 9999 32px;
    }
  }

  &.mobile {
    padding: 56px 16px 16px;

    .closeFix {
      max-height: 40px;
      flex: 9999 9999 40px;
    }
  }
}

.overlay {
  position: absolute;
  background-color: var(--color-text-main);
  opacity: 0.9;
  z-index: 100;
  inset: 0;
}

.contentWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 101;
}

.closeMobile {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  cursor: pointer;
  color: var(--color-background-main);
}

.closeDesktop {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  font-size: 11px;
  transform: translate(100%, 0);
  cursor: pointer;
  color: var(--color-background-main);
}
