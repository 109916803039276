.dropdownItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;

  .label {
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
}

.options {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px 8px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: var(--border-radius-inner);
  background-color: var(--color-background-main);
  cursor: pointer;
  color: var(--color-text-main);
}

.actions {
  display: flex;
  align-items: center;
}

.borders {
  padding: 0 8px;
  border-right: 1px solid var(--color-delimiter-main);
  border-left: 1px solid var(--color-delimiter-main);
}

.settings {
  display: flex;
  align-items: center;
}

.iconActive {
  color: var(--color-primary-active);
}
