.icon {
  position: relative;
  padding: 30px 10px 30px 25px;
  font-size: 24px;
}

.iconType {
  position: absolute;
  display: flex;
  width: 26px;
  height: 16px;
  bottom: 28px;
  left: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--color-primary-default);
  font-weight: 500;
  font-size: 10px;
  line-height: 8px;
  color: var(--color-background-main);
}

.size {
  margin-right: 32px;
  margin-left: 12px;
  line-height: 18px;
  text-align: right;
  white-space: nowrap;
}

.text {
  flex-grow: 1;
  text-decoration: inherit;
  overflow: hidden;
  color: inherit;

  & > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
