.group {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 13px;
}

.fieldLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.label {
  color: var(--color-text-secondary);
}

.field {
  display: flex;
  gap: 8px;
}

.prefix {
  padding-left: 4px;
  color: var(--color-placeholder);
}
