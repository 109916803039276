.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 16px;
  padding: 14px 0;
}

.title {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.content {
  padding-left: 32px;
}
