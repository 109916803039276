.root {
  position: relative;
  display: flex;
  height: 28px;
  flex: 1;
  justify-content: space-between;
}

.reverse {
  flex-direction: row-reverse;
}

.left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.content {
  display: flex;
  align-items: center;
  gap: 4px;
}

.actions {
  position: absolute;
  top: 50%;
  left: 0;
  flex-grow: 1;
  transform: translateY(-50%);
  z-index: 1;
}
