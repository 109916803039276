.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vertical {
  flex-direction: column;
  align-items: flex-start;
}

.horizontal {
  flex-direction: row;

  &.fluid {
    width: 100%;
  }
}
