.root {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-delimiter-secondary);
  border-radius: 6px;
  background-color: var(--color-background-secondary);
  font-weight: 400;

  &.fluid {
    flex-grow: 1;
  }
}

.label {
  flex-grow: 1;
  transition: var(--transition-duration) ease-in-out;

  &:focus-visible {
    outline: 2px solid var(--color-input-focus);
    outline-offset: -1px;
    z-index: 10;
  }
}

.item {
  display: flex;
  min-width: 54px;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  font-size: 13px;
  transition: all 0.3s;
  cursor: pointer;
  color: var(--color-placeholder);

  &.active {
    background-color: var(--color-background-main);
    color: var(--color-text-main);
  }

  &.disabled {
    cursor: not-allowed;
    color: var(--color-placeholder);
  }

  &.xmini {
    height: 22px;
    padding: 2px 0;
  }

  &.mini {
    height: 26px;
    padding: 4px 0;
  }

  &.medium {
    height: 30px;
    padding: 6px 0;
  }

  &.large {
    height: 34px;
    padding: 8px 0;
  }
}

.input {
  position: absolute;
  opacity: 0;
  appearance: none;
}

.option {
  display: flex;
  width: 100%;
  height: 100%;

  .divider {
    width: 1px;
    height: 100%;
    background-color: var(--color-delimiter-secondary);
  }

  &:last-child {
    .divider {
      display: none;
    }

    .item,
    .label {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &:first-child {
    .item,
    .label {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
}
