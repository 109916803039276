.blockActions {
  position: absolute;
  display: none;
  top: 4px;
  right: 88px;
}

.isComment {
  position: absolute;
  display: flex;
  width: 24px;
  height: 24px;
  top: 6px;
  left: -8px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-text-main);
  transition: background-color var(--transition-duration);
  transform: translateX(-100%);
  cursor: pointer;
  color: var(--color-background-main);

  &.commentActive {
    background-color: var(--color-secondary-default);
  }
}

.canvas {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: auto;
  background-color: var(--brand-primaryBackgroundColor);
  transition: background-color var(--transition-duration);
  z-index: 0;

  &:hover .blockActions {
    display: flex;
  }
}

.isDone {
  background-color: var(--color-delimiter-main);
}

.active {
  background-color: var(--color-primary-selected);
}

.hover:hover {
  background-color: var(--color-primary-selected);
}

@media print {
  .canvas {
    display: block !important;
  }
}
