.root {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.header {
  position: relative;
  z-index: 1001;
}

.body {
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: var(--brand-primaryBackgroundColor);

  &.positionsRight {
    flex-direction: row-reverse;
  }
}

.contentWrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.tooltipPortal {
  position: absolute;
  pointer-events: none;
  z-index: 1002;
  inset: 0;

  > div {
    pointer-events: auto;
  }
}

.trial {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 8px;
}
