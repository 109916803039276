.item {
  display: flex;
  grid-area: section;
  overflow: hidden;
}

.itemIcon {
  display: flex;
  align-items: center;
}

.itemContent {
  width: 100%;
  flex-grow: 1;
  word-break: break-all;
  overflow: hidden;
}

.iconImage {
  width: 20px;
  height: 20px;
  grid-area: icon;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
