.italic {
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-main);
  background-color: var(--color-background-secondary);

  &.disabled {
    background-color: var(--color-disabled);
    cursor: not-allowed;
  }
}
