.root {
  position: relative;
  display: flex;
  max-width: 1200px;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px 0 32px;
  padding: 12px;
  border: 1px solid var(--color-delimiter-main);
  border-radius: 12px;
  background-color: var(--color-background-secondary);
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;

  &.tablet,
  &.mobile {
    align-items: flex-start;
    margin: 8px 0 32px;
  }
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.mobile {
  display: flex;
}

.title {
  display: flex;
  align-items: center;
  color: var(--color-text-main);
}

.description {
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-secondary);
}

.link {
  text-decoration: underline;
}

.footer {
  position: absolute;
  display: flex;
  height: 25px;
  bottom: 24px;
  left: 24px;
  gap: 4px;

  &.centered {
    position: relative;
    bottom: auto;
    left: auto;
  }
}
