.root {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
}

.footer {
  width: 100%;
}

.content {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}
