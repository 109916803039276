.root {
  position: relative;
  overflow: hidden;
}

.slideArrow {
  flex: 1 1 0;
  overflow: hidden;
}

@media print {
  .root {
    display: block;
  }

  .item {
    break-inside: avoid;
    margin-bottom: 5px;
  }
}
