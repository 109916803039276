.root {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  &:not(.selected, .disabled):hover {
    background-color: var(--color-primary-hover);
  }
}

.today {
  font-weight: 400;
  color: var(--color-primary-default);
}

.selected {
  border-radius: 50%;
  background-color: var(--color-primary-default);
  color: var(--color-background-main);
}

.disabled {
  background-color: transparent;
  cursor: not-allowed;
  color: var(--color-disabled);
}
