.highlight {
  padding: 0;
  background-color: var(--color-yellow-alpha);
  color: inherit;
}

.root {
  white-space: pre-wrap;
  word-break: keep-all;
}

.breakWord {
  word-break: break-word;
}
