.root {
  display: flex;
  height: 1rem;
  flex: 1;
  align-items: center;
  gap: 0.5rem;
  font-weight: var(--text-fontWeight);
  font-size: var(--text-fontSize);

  &.reverse {
    flex-direction: row-reverse;
  }

  .progressBar {
    position: relative;
    display: inline-block;
    width: 100%;
    flex: 1;
    border-radius: 100px;
    background-color: var(--color-delimiter-main);
    line-height: 0;
    overflow: hidden;
    vertical-align: middle;

    .statusBar.warning {
      background: var(--color-warning);
    }

    .statusBar.danger {
      background: var(--color-red);
    }

    .statusBar {
      position: relative;
      height: 6px;
      border-radius: 100px;
      background: var(--color-primary-default);
      transition: all 0.5s ease-in-out;
    }
  }

  &.line {
    height: auto;
    gap: var(--progress-gap);
    font-weight: var(--text-fontWeight);
    font-family: var(--text-fontFamily);

    .progressBar {
      border-width: var(--progress-borderWidth);
      border-style: var(--progress-borderStyle);
      border-color: var(--progress-borderColor);
      border-radius: var(--progress-borderRadius);
    }

    .statusBar {
      height: 8px;
      border-radius: var(--progress-borderRadius);
      border-top-left-radius: calc(var(--progress-borderRadius) - var(--progress-borderWidth));
      border-bottom-left-radius: calc(var(--progress-borderRadius) - var(--progress-borderWidth));
    }
  }

  &.mini {
    justify-content: space-between;

    .progressBar {
      width: 79px;
      max-width: 79px;

      .statusBar {
        height: 2px;
      }
    }

    .fluid {
      width: 100%;
      max-width: 100%;
      flex-grow: 1;
    }
  }
}

.circle {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;
  align-items: center;
  gap: 0.5rem;
  font-style: var(--textPercents-fontStyle);
  font-family: var(--textPercents-fontFamily);
  color: var(--textPercents-color);

  .circleProgress {
    width: 166px;
    height: 166px;
    transform: rotate(-90deg);

    .stroke,
    .trail {
      fill: transparent;
    }

    .trail {
      stroke: var(--color-delimiter-main);
      stroke-width: 10px;
    }

    .stroke {
      stroke: var(--color-primary-default);
      stroke-width: 10px;
      stroke-linecap: round;

      &.warning {
        stroke: var(--color-warning);
      }

      &.danger {
        stroke: var(--color-red);
      }
    }
  }

  &.mini {
    .circleProgress {
      width: 18px;
      height: 18px;

      .trail {
        stroke: var(--color-delimiter-main);
        stroke-width: 3.5px;
      }

      .stroke {
        stroke-width: 3.5px;
        stroke-linecap: inherit;
      }
    }
  }

  &.medium {
    .circleProgress {
      width: 24px;
      height: 24px;

      .trail {
        stroke: var(--color-delimiter-main);
        stroke-width: 2px;
      }

      .stroke {
        stroke-width: 2px;
        stroke-linecap: inherit;
      }
    }
  }
}

.progressWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.percent {
  position: absolute;
  font-weight: var(--textPercents-fontWeight);
  font-size: var(--textPercents-fontSize);
}
