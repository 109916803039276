.root {
  display: flex;
}

.stepWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.step {
  display: flex;
  align-items: center;
}

.circle {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  background-color: var(--color-delimiter-main);
  font-size: 13px;
  color: var(--color-text-secondary);

  &.alert {
    background-color: var(--color-yellow-alpha-bg);
  }

  &.active {
    border: 1px solid var(--color-delimiter-secondary);
    background-color: transparent;
    color: var(--color-background-dark);
  }

  &.disabled {
    background-color: var(--color-placeholder);
    cursor: not-allowed;
  }

  &.completed {
    border: none;
    background-color: var(--color-green-alpha);
  }
}

.divider {
  width: 20px;
  height: 1px;
  margin: 0 20px;
  background-color: var(--color-delimiter-secondary);
}

.text {
  text-decoration: underline solid rgba(29, 43, 75, 0.3) 1px;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: var(--color-background-dark);
}

.completedIcon {
  color: var(--color-primary-default);
}

.disabledIcon {
  color: var(--color-delimiter-main);
}
