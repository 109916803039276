.root {
  position: relative;
  display: flex;
  min-height: 32px;
}

.lineContainer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.divider {
  width: 100%;
  border-top: 1px solid var(--color-text-secondary);
}

.number {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.numberExplanationWrapper {
  display: flex;
  margin-bottom: 16px;
  color: var(--color-text-secondary);
}

.numberExplanation {
  margin-left: 4px;
  font-size: 12px;
  line-height: 18px;
}
