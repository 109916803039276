.root {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.me {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-primary-default);
  border-radius: 50%;
  box-shadow: var(--box-shadow-small);
  background-color: var(--color-background-main);
  cursor: pointer;
  user-select: none;
}

.input {
  position: relative;
  width: 294px;
  top: -6px;
  padding: 13px 16px;
  border-radius: var(--border-radius-main);
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);
  cursor: default;
  overflow: hidden;
}
