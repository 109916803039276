/* временные стили до глобального изменения иконок в проекте */

.root {
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  line-height: 1;
  vertical-align: -0.125em;

  &.xmini {
    font-size: 16px;
  }

  &.mini {
    font-size: 20px;
  }

  &.medium {
    font-size: 24px;
  }

  &.large {
    font-size: 28px;
  }
}
