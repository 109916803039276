.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  user-select: none;
}

.tag {
  padding: 5px 12px;
  border-radius: 100px;
  background-color: var(--color-background-secondary);
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    color: var(--color-primary-default);
  }

  &.disabled {
    padding: 4px 12px;
    border: 1px solid var(--color-delimiter-main);
    background-color: var(--color-disabled);
    cursor: not-allowed;
    color: var(--color-text-secondary);
  }

  &.checked {
    background-color: var(--color-primary-default);
    color: var(--color-background-main);

    &.disabled {
      background-color: var(--color-disabled);
      color: var(--color-text-secondary);
    }
  }
}

.clearButton {
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    color: var(--color-primary-default);
  }

  &:active {
    color: var(--color-input-focus);
  }
}
