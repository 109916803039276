.root {
  display: flex;
}

.item {
  min-width: 10px;
  max-width: 100%;
}

@media print {
  .root {
    display: block;
  }

  .item {
    break-inside: avoid;
  }
}
