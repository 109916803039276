.root {
  display: flex;
  max-height: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.blockPreview {
  display: flex;
  height: 784px;
  flex-grow: 1;
  padding: 24px;
  background-color: var(--color-background-secondary);
  overflow: hidden;
}

.panel {
  display: flex;
  height: 784px;
  flex: 0 0 300px;
  flex-direction: column;
  overflow: hidden;
}

.button {
  display: flex;
  padding: 12px 24px 32px;
}

.title {
  padding: 24px 24px 12px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

.history {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.historyList {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  overflow: hidden;
}

.version {
  align-items: center;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;

  .avatar span {
    margin-right: 8px;
    font-size: 11px;
    line-height: 16px;
    color: var(--color-text-secondary);
  }

  .versionName {
    flex-grow: 1;
  }

  &:hover {
    background-color: var(--color-primary-hover);
  }

  &.active {
    background-color: var(--color-primary-selected);
  }
}

.release {
  margin-bottom: 12px;
  margin-left: 12px;
  font-size: 11px;
  color: var(--color-text-secondary);
}

.icon {
  font-size: 12px;
  color: var(--color-violet);
}

.date {
  font-size: 13px;
}
