.root {
  position: absolute;
  display: flex;
  min-width: 200px;
  max-width: 300px;
  max-height: 300px;
  top: 0;
  left: 0;
  flex-direction: column;
  border-radius: var(--border-radius-inner);
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);
  overflow: hidden;
  z-index: 10001; // equal dropdownPortal
}

.item {
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-primary-hover);
  }
}

.selected {
  background-color: var(--color-primary-selected);
}
