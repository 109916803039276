.root {
  display: flex;
  max-height: 100%;
  flex-direction: column;
  overflow: hidden;
}

.content {
  flex-grow: 1;
  padding: 0 24px;
  overflow-y: scroll;
}

.groupTitle {
  font-size: 12px;
  color: var(--color-text-secondary);
}

.itemContainer {
  height: 114px;
}

.itemIcon {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }
}

.itemName {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

.searchHighlight {
  background-color: var(--color-yellow);
}

.searchContainer {
  border-bottom: 1px solid var(--color-delimiter-main);
}

.searchForm {
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 16px;
}

.tags {
  padding: 20px 24px;
}
