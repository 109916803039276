.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 42px;
  margin-left: 18px;
  cursor: pointer;
}

.rightLine {
  right: 5px;
}

.floatingButton {
  position: absolute;
  display: flex;
  width: 40px;
  height: 40px;
  top: 8px;
  left: 12px;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  margin-left: 0;
  border-radius: 50%;
  background-color: var(--brand-primaryBackgroundColor);
  z-index: 1001; // more than the drawer
}

.positionRight {
  right: 12px;
  left: auto;
  margin-right: 18px;
  margin-left: 24px;

  &.floatingButton {
    margin-right: 0;
  }
}

.customMenu {
  width: 24px;
  height: 24px;
  background-size: cover;
}

.customBurger {
  position: relative;
  width: 28px;
  height: 28px;
}
