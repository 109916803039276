body {
  background-color: var(--color-background-main);
  color: var(--color-text-main);

  .ant-modal {
    color: var(--color-text-main);
  }

  .dragging:not(.ant-input) {
    border-radius: var(--border-radius-inner);
    box-shadow: 0 0 0 2px var(--color-secondary-default);
  }

  .dragging.ant-input {
    border-color: var(--color-secondary-default);
  }

  a {
    color: var(--color-link);
  }

  a:hover,
  a:active,
  a:focus {
    color: var(--color-link);
    // color: var(--color-link-hover);
  }

  .ant-spin {
    color: var(--color-primary-default);
  }
}
