.root {
  position: absolute;
  min-width: 1px;
  flex-grow: 1;
  flex-shrink: 1;
  box-sizing: content-box;
  border: none;
  background-color: transparent;
  line-height: 18px;
  cursor: pointer;
  opacity: 0;
  z-index: -1;
  color: var(--color-text-main);

  &::placeholder {
    color: var(--color-placeholder);
  }

  &.xmini {
    font-size: 12px;
  }

  &.mini {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 13px;
  }

  &.medium {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px;
  }

  &.large {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 13px;
  }
}

.base:first-child {
  &.xmini {
    padding-left: 6px;
  }

  &.mini {
    padding-left: 6px;
  }

  &.medium {
    padding-left: 5px;
  }

  &.large {
    padding-left: 7px;
  }
}

.show {
  position: static;
  cursor: text;
  opacity: 1;
  z-index: 0;
}

.disabled {
  cursor: not-allowed;
  color: var(--color-text-secondary);
}
