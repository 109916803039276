.border {
  position: absolute;
  pointer-events: none !important;
  inset: -1px;
}

.actions {
  pointer-events: auto !important;
}

.comments {
  position: absolute;
  top: 1px;
  left: 1px;
  pointer-events: auto !important;
}

.addTop {
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: auto !important;
}

.addBottom {
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translate(-50%, 50%);
  pointer-events: auto !important;
}

.addLeft {
  position: absolute;
  top: 50%;
  left: -1px;
  transform: translate(-50%, -50%);
  pointer-events: auto !important;
}

.addRight {
  position: absolute;
  top: 50%;
  right: -1px;
  transform: translate(50%, -50%);
  pointer-events: auto !important;
}
