.iconZoomIcon {
  position: absolute;
  display: flex;
  width: 28px;
  height: 28px;
  right: 8px;
  bottom: 8px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.6);
  object-position: 50% 50%;
}
