.root {
  width: 100%;
  overflow: scroll hidden;

  :global {
    .recharts-legend-item {
      margin-bottom: 16px;
    }

    .recharts-text.recharts-label {
      text-anchor: middle;
    }
  }
}

.tooltip {
  padding: 4px 12px;
  border-radius: 6px;
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-main);
}
