.selectedLabel {
  display: flex;
  color: var(--color-text-secondary);

  &:hover {
    color: var(--color-primary-default);
  }
}

.selected {
  margin: 0 4px;
}
