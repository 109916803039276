.root {
  max-width: 100%;
  padding: 6px;
  border: none;
  border-radius: var(--border-radius-inner);

  .image {
    height: 32px;
    max-width: 100%;
    max-height: 32px;
    object-fit: contain;
  }
}

.active {
  cursor: pointer;
}
