.rich-text__el {
  display: flex;
  min-height: 100%;
  max-height: 100%;
  flex-direction: column;
  font-weight: var(--body-fontWeight);
  font-style: var(--body-fontStyle);
  font-size: var(--body-fontSize);
  font-family: var(--body-fontFamily);
  line-height: var(--body-lineHeight);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  color: var(--body-color);
}

.rich-text__el::-webkit-scrollbar {
  display: none;
}

@media print {
  .rich-text__el {
    display: block;
  }
}
