.root {
  position: relative;
  display: flex;
  width: 116px;
  gap: 4px;
  padding-right: 8px;
}

.label {
  margin-right: 8px;
}

.input {
  width: 100%;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: var(--box-shadow-default);
    background: var(--color-background-main);
    z-index: 1;
    -webkit-appearance: none;
  }
}

.inputRoot {
  position: relative;
  display: flex;
}

.track {
  position: absolute;
  width: 100%;
  height: 4px;
  top: 50%;
  border-radius: 2px;
  background-color: var(--color-background-secondary);
  transform: translateY(-50%);
  pointer-events: none;
  overflow: hidden;
}

.volume {
  position: absolute;
  height: 100%;
  background-color: var(--color-primary-default);
}
