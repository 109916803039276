.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
  font-size: 13px;

  &:first-child {
    margin-top: 0;
  }
}

.titleList {
  margin-right: 16px;
}

.label {
  margin-bottom: 12px;
  font-size: 11px;
  color: var(--color-text-secondary);

  &:not(:first-child) {
    margin-top: 16px;
  }
}

.delimiter {
  width: 100%;
  height: 1px;
  margin-top: 13px;
  background-color: var(--color-delimiter-main);
}
