.input {
  width: 100%;
  border: 1px solid #eee;
  border-radius: var(--brand-borderRadius);
  outline: none;

  &[disabled] {
    background-color: var(--color-delimiter-main);
    cursor: not-allowed;
  }

  &::placeholder {
    color: var(--placeholder-color);
  }

  // height: auto;
  // padding: 15px 24px;
}
