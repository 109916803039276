* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0 solid transparent;
}

body {
  font-size: 13px;
  font-family: Inter, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.571;
  font-variant-ligatures: none;
}

a {
  text-decoration: none;
}
