.icon {
  transition: transform 0.4s ease;

  &.right {
    transform: rotate(180deg);

    &.isOpen {
      transform: rotate(90deg);
    }
  }

  &.left {
    &.isOpen {
      transform: rotate(90deg);
    }
  }
}
